import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import "./about.styles.scss";

class AboutPage extends Component {
  // component is already built into react so this leverages that
  constructor(props) {
    super(props); // calls the constructor on the component class to give us access to state methods
    // now the state property exists on our App class

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="AboutPage">
        <Row>
          <Col
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            className="about-text-container"
          >
            <h1 className="page-title">A little about Heartening</h1>
            <h2>
              Heartening is on a mission to show every unwanted item where it is
              wanted.
            </h2>
            <p>
              This project is built on the belief that if unwanted items knew
              where they were needed, people would help them get there.
            </p>
            <br />
            <p>
              Unwanted items usually wait years gathering dust in a closet, a
              garage, or storage unit before getting tossed into the landfill.
              Meanwhile, those very same items could answer the very pressing
              needs of people nearby. But, items don’t know that. So, we must
              help them find their way.
            </p>
            <br />
            <Row>
              <Col sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 3 }}>
                <img
                  className="about-me-circle-imgs"
                  width="100%"
                  src="https://prinsesstarta.s3.amazonaws.com/about-me-assets/kelley-rytlewski.png"
                />
              </Col>
              <Col sm={{ span: 7 }} md={{ span: 8 }} lg={{ span: 9 }}>
                <p>
                  This idea is starting small in Austin but has a heart as big
                  as Texas. The hands behind Heartening belong to me, Kelley. Hi
                  there! I'm the zero-waste eco-minded caretaker of Heartening.
                  I am a scrappy Texas woman who cares deeply about rescuing
                  items for reuse right here in my own community. I have no
                  funding, no grants, and I'm not on anyone's payroll.
                </p>
              </Col>
            </Row>

            <br />
            <p>
              I began this project as an experiment, wondering "If people knew
              where their items were needed, would they still dump them
              anyway?". My heart was sick seeing perfectly good items tossed
              into dumpsters and left in the trashroom of my apartment building,
              watching loads of belongings tossed unceremoniously when neighbors
              moved out. I took to taping up signs in the trash rooms, listing
              where items could be donated. I left my cell number and people
              would message me with questions about random items and I'd
              research and add to the list. Then, it became a giant spreadsheet.
              Then, eventually, eventually, this website.
            </p>
            <br />
            <Row>
              <Col sm={{ span: 7 }} md={{ span: 8 }} lg={{ span: 9 }}>
                <p>
                  This project is still a journey of learning and research for
                  me. I continue to meticulously study where items are needed
                  and wanted all over Austin. I catalog where to donate items
                  right here on my website. I put my cowboy boots on the ground
                  to do it myself, too. I pick up items from porches, haul,
                  sort, clean, and organize to make beautiful donation bundles.
                  I visit local causes to drop off, chat with staff and
                  volunteers, and learn something new every day.
                </p>
              </Col>
              <Col sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 3 }}>
                <img
                  className="about-me-circle-imgs"
                  width="100%"
                  src="https://prinsesstarta.s3.amazonaws.com/about-me-assets/donation-bundle.png"
                />
              </Col>
            </Row>

            <br />
            <p>
              Each day, I believe more and more that we, as a community, are
              rich in what we share together, but poor in what we keep alone. I
              believe that we already have everything we need — we just need to
              share it. I believe that giving items should be more like gifting
              items. When we donate items with extra love, then strangers become
              neighbors and neighbors become friends. I believe Austin can set
              an example that will inspire the world. And that, to me, is
              Heartening.
            </p>
            <br />
            <p>
              Tips help me keep this project going. If you find this project
              useful, you can support Heartening by sending tips through
              <a
                href="https://www.venmo.com/u/Heartening"
                target="_blank"
                className="link-to-outside-pages"
              >
                {" "}
                venmo @heartening
              </a>{" "}
              or
              <a
                href="https://www.buymeacoffee.com/heartening"
                target="_blank"
                className="link-to-outside-pages"
              >
                {" "}
                buy me a coffee.
              </a>
            </p>

            <p>With love,</p>
            <br />
            <p>Kelley</p>
            <br />
            <p>
              Kelley Rytlewski
              <br /> <br />
              Email:{" "}
              <span className="link-to-outside-pages">
                kelley@heartening.org
              </span>{" "}
              <br />
              Instagram:{" "}
              <a
                href="https://www.instagram.com/hearteningaustin/"
                target="_blank"
                className="link-to-outside-pages"
              >
                @hearteningaustin
              </a>
              <br />
              Venmo: <span className="link-to-outside-pages">@heartening</span>
            </p>
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}

export default AboutPage;
