import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import HomePage from "./pages/homepage/homepage.component.jsx";
import CharitiesPage from "./pages/charities/charities.component";
import CharityPage from "./pages/charity/charity.component";
import AdminWishlistPage from "./pages/admin-wishlist/admin-wishlist.component";
import AboutPage from "./pages/about/about.component";
import Header from "./components/header/header.component.jsx";
import { auth, createUserProfileDocument } from "./firebase/firebase.utils";
import { setCurrentUser } from "./redux/user/user.actions";
import { selectCurrentUser } from "./redux/user/user.selectors";
import { createStructuredSelector } from "reselect";

class App extends React.Component {
  // constructor() {    // REDUX ELIMINATED
  //   super();
  //   this.state = {
  //   }
  // }                   // END OF REDUX ELIMATED SNIPPET
  // this this property on our class
  unsubscribeFromAuth = null;

  // lifecycle method - our application will listen to auth state changes now on firebase backend
  componentDidMount() {
    const { setCurrentUser } = this.props;

    // takes a function where the parameter is the user state of the auth on our firebase project
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        // check if they're signed in
        const userRef = await createUserProfileDocument(userAuth); // get the userRef object - if not in db, will create it

        // then we will subscribe to this userRef to listen to any changes to that data
        // also get back the first state of that data and set the state in our local app
        // calling on snapShot gives us back a snapshot obj where we will get the data related to this user
        userRef.onSnapshot((snapShot) => {
          // this.setState({   // REDUX ELIMINATED
          //   currentUser : {
          //     id : snapShot.id,
          //     ...snapShot.data()
          //   }
          // }                 // END OF REDUX ELIMINATED
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data(),
          });
        });
        //, () => {
        //console.log(this.state); // because setState is async so may not finish by time we call console log
        //}
        //console.log(this.state);
      } else {
        // if userAuth object comes back null
        setCurrentUser(userAuth); // equivalent to saying set current user to null
      }
    });
  }

  // lifecycle method
  componentWillUnmount() {
    // this will close the subscription
    //this.unsubscribeFromAuth();
  }

  render() {
    return (
      <div>
        {/* putting header here keeps it ever-present on the pages, outside the switch*/}
        <BrowserRouter>
          <Header />
          <Switch>
            <Route exact path="/needing" component={CharitiesPage} />
            <Route exact path="/about" component={AboutPage} />
            {/* <Route exact path="/admin" component={AdminWishlistPage} />
            <Route exact path="/admin-wishlist" component={AdminWishlistPage} /> */}
            <Route exact path="/giving" component={HomePage} />
            <Route exact path="/items" component={HomePage} />
            <Route exact path="/causes" component={CharitiesPage} />
            <Route exact path="/:searchParam" component={HomePage} />
            <Route exact path="/" component={HomePage} />

            <Route exact path="/charities" component={CharitiesPage} />
            <Route
              exact
              path="/charity/:charityUrlParam"
              component={CharityPage}
            />

            <Route
              exact
              path="/needing/:searchParam"
              component={CharitiesPage}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  // dispatch says whatever object you pass me is going to be an action obj that I pass to every reducer
  // we're invoking setCurrentUser with the user that will then be used as the payload
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
