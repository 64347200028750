import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import CharityDetail from "../../components/charity-detail/charity-detail.component";
import { db } from "../../firebase/firebase.utils";

import "./charity.styles.scss";

class CharityPage extends Component {
  // component is already built into react so this leverages that
  constructor(props) {
    super(props); // calls the constructor on the component class to give us access to state methods
    // now the state property exists on our App class

    this.state = {
      charities: [], // sets some property on our state object
      //: ""
    };
  }

  componentDidMount() {
    // life cycle method

    var charitiesRef = db.collection("charities");
    //let { charityUrlParam } = useParams();
    const charityUrlParam = this.props.match.params.charityUrlParam;

    // https://stackoverflow.com/questions/58548767/react-router-dom-useparams-inside-class-component
    var queryCharities = charitiesRef
      .where("charityUrlParam", "==", charityUrlParam)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        //console.log(data); // array of items objects
        this.setState({ charities: data });
      });

    // GET ALL CHARITIES and set to array on the needItems property of state
    // db.collection("charities").orderBy("name")
    // .get()
    // .then(querySnapshot => {
    //   const data = querySnapshot.docs.map(doc => doc.data());
    //   console.log(data); // array of items objects
    //   this.setState({ charities: data });
    // });
  }

  render() {
    // using a concept called destructuring which allows us to pull props off an obj and set them to constants
    // pulls the needItems and searchField props off state and sets them to contstants called needItems and searchField
    // its the euquivalent of saying const needItems = this.state.needItems; and const searchField = this.state.searchField;

    // this accesses the url params in the render method but we needed them in componentDidMount
    //const charityUrlParam = this.props.match.params.charityUrlParam;
    const { charities } = this.state; // the state property is what we want to pull the props off of
    const displayCharities = charities;
    //console.log(displayCharities);

    return (
      <div className="CharityPage">
        <Container className="charity-card-list">
          <Row className="list-container">
            {displayCharities.map((
              charity // map iterates over every element so the function we pass in will be called on each
            ) => (
              <CharityDetail
                key={charity.id}
                charity={charity}
                charityId={charity.id}
                isExpanded={true}
                donationDetailsLink={charity.donationDetailsLink}
                donationInstructions={charity.donationInstructions}
                donationFormUrl={charity.donationFormUrl}
                donationAddress={charity.donationAddress}
                donationAddress2={charity.donationAddress2}
                donationAddress3={charity.donationAddress3}
                donationAddress4={charity.donationAddress4}
                donationAddress5={charity.donationAddress5}
                donationAddress6={charity.donationAddress6}
                donationAddressMapLink={charity.donationAddressMapLink}
                donationGoogleLocation={charity.donationGoogleLocation}
                donationCoordinationEmail={charity.donationCoordinationEmail}
                donationCoordinationName={charity.donationCoordinationName}
                offersPickup={charity.offersPickup}
                weCannotPickup={charity.weCannotPickup}
                charityName={charity.name}
              /> // this passes a charityItem into the CharityCard component
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default CharityPage;
