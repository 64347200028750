import React from "react";
import { Container, Row } from "react-bootstrap";
import CharityCard from "../charity-card/charity-card.component";
import "./charity-card-list.styles.scss";

// components take in props
export const CharityCardList = (props) => (
  // className of charity-card-list applies our css
  // .charityItems was a prop that we passed in when we called CharityCardList
  <Container className="charity-card-list">
    <Row className="list-container">
      {props.charities.map((
        charity // map iterates over every element so the function we pass in will be called on each
      ) => (
        <CharityCard
          key={charity.id}
          charity={charity}
          donationDetailsLink={charity.donationDetailsLink}
          donationInstructions={charity.donationInstructions}
          donationCoordinationEmail={charity.donationCoordinationEmail}
          donationCoordinationName={charity.donationCoordinationName}
          donationFormUrl={charity.donationFormUrl}
          donationAddress={charity.donationAddress}
          donationAddress2={charity.donationAddress2}
          donationAddress3={charity.donationAddress3}
          donationAddress4={charity.donationAddress4}
          donationAddress5={charity.donationAddress5}
          donationAddress6={charity.donationAddress6}
          donationAddressMapLink={charity.donationAddressMapLink}
          donationGoogleLocation={charity.donationGoogleLocation}
          charityId={charity.id}
          charityName={charity.name}
          orgType={charity.orgType}
          offersPickup={charity.offersPickup}
          weCannotPickup={charity.weCannotPickup}
        /> // this passes a charityItem into the CharityCard component
      ))}
    </Row>
  </Container>
);
