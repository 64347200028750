import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // says we want to use local storage as our storage
// alternatively you could import session storage from another library in redux-persist

// each of these must also be added to the keys in the const rootReducer
import userReducer from './user/user.reducer';
import cartReducer from './cart/cart.reducer';
import directoryReducer from './directory/directory.reducer';
import shopReducer from './shop/shop.reducer';

// just a JSON that represents the possible configurations we want redux persist to use
const persistConfig = {
    key: 'root',
    storage, // goes to the storage object we imported in from redux persist

    // contains an array of the string names of the reducers we want to store
    // firebase handles our user so we don't need to add it here
    whitelist: ['cart'] 
};

// this is the overall reducer
// we pull in combine reducer from redux library
// our full state is one big object in redux, the keys that represent the slices of state are the reducers

// here, the key points to the reducer that we want
const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    directory: directoryReducer,
    shop: shopReducer
});



export default persistReducer(persistConfig, rootReducer);