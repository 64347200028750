import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { CharityCardList } from "../../components/charity-card-list/charity-card-list.component";
import { SearchBox } from "../../components/search-box/search-box.component";
import { db } from "../../firebase/firebase.utils";

// search library
import Fuse from "fuse.js";
import "./charities.styles.scss";

class CharitiesPage extends Component {
  // component is already built into react so this leverages that
  constructor(props) {
    super(props); // calls the constructor on the component class to give us access to state methods
    // now the state property exists on our App class

    this.state = {
      charities: [], // sets some property on our state object
      searchField: "",
      id: props.match.params,
    };
  }

  componentDidMount() {
    // life cycle method

    // GET ALL CHARITIES and set to array on the needItems property of state
    db.collection("charities")
      .orderBy("name")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        //console.log(data); // array of items objects
        this.setState({ charities: data });
      });
  }
  // this arrow syntax handles binding 'this' to the place that the arrow function was defined in the first place
  // so the context of this arrow function is our App component
  // this is called 'lexically - scoped' function
  // when javascript first defined our app component, it saw this as an arrow function and
  // when it came into existence bound it to that context
  handleChange = (e) => {
    this.setState({ searchField: e.target.value, title: e.target.value });
  };

  render() {
    // using a concept called destructuring which allows us to pull props off an obj and set them to constants
    // pulls the needItems and searchField props off state and sets them to contstants called needItems and searchField
    // its the euquivalent of saying const needItems = this.state.needItems; and const searchField = this.state.searchField;

    const { charities, searchField } = this.state; // the state property is what we want to pull the props off of

    // OLD FILTER SEARCH
    // const filteredCharities = charities.filter( charity =>
    //     // so our search isn't case sensitive
    //     // then asks if the string value passed inside of our includes is actually in the string being called on
    //     charity.name.toLowerCase().includes(searchField.toLowerCase())
    //   );

    // FUSE - FUZZY SEARCH
    const options = {
      isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 2,
      location: 0,
      threshold: 0.4,
      distance: 100,
      useExtendedSearch: false,
      ignoreLocation: true,
      ignoreFieldNorm: false,
      keys: ["name", "category"],
    };
    const fuse = new Fuse(charities, options);
    // Change the pattern
    const pattern = searchField.toLowerCase();

    const fuseResults = fuse.search(pattern);
    //console.log(fuse.search(pattern));

    const filteredCharities = searchField
      ? fuseResults.map((charities) => charities.item)
      : charities;

    return (
      <div className="CharitiesPage">
        <h1 className="page-title">
          Good causes to donate items to in Austin, Texas
        </h1>
        <div className="SearchForCharity charity-search-container">
          {/* e represents the synthetic event*/}
          <SearchBox
            className="charity-search-input"
            placeholder="search for a good cause"
            handleChange={this.handleChange}
          />
          <CharityCardList charities={filteredCharities}></CharityCardList>
        </div>
        <Row className="add-a-charity-message">
          <Col sm={10} md={7} className="add-charity-message-container">
            <p>
              Do you have an organization needing in-kind donations that you'd
              like to add? <br />
              Email kelley@heartening.org
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CharitiesPage;
