import React from 'react';

import './search-box.styles.css';

// functional components don't have access to state because they don't access constructor which is a class method
// also no lifecycle methods
// sometimes all we want to do is render some html and that is what a functional component really is
// functional component just gets some props and returns some html
export const SearchBox = ({ placeholder, handleChange }) => (
    <input 
    className = "search" // what our css styles apply to
    type="search" 
    placeholder= {placeholder} // make the placeholder text dynamic so search boxes everywhere can use this component
    onChange= {handleChange}
  />
);