import { UserActionTypes } from './user.types';

const INITIAL_STATE = {
    currentUser: null
}

// if state is ever not defined, it will fall back and leverage this initial state
// this is a function that gets a state object and an action
// depending on the type of action, the switch statement will check, if the case matches, it returns new state
// if none matches, it returns the current state
const userReducer = (state = INITIAL_STATE, action) => {
    // action type is a string
    switch(action.type){
        case UserActionTypes.SET_CURRENT_USER :
            return {
                // spread in everything else on the state
                ...state,
                currentUser: action.payload
            };

        default:
            return state;
    }
};

export default userReducer;