import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// pulled from firebase website SDK snippet

// create react app is friendly enough to place our env values here
// not working yet
const {FIREBASE_API_KEY} = process.env;

const config =  
    {
        
        apiKey: "AIzaSyD6Lw-AI1-SPX1vzhYPbHs_kR86EWFraAI",
        authDomain: "heartstore-db.firebaseapp.com",
        databaseURL: "https://heartstore-db.firebaseio.com",
        projectId: "heartstore-db",
        storageBucket: "heartstore-db.appspot.com",
        messagingSenderId: "748115623263",
        appId: "1:748115623263:web:8579ffcde06570eb0b8aea",
        measurementId: "G-07BG5GQWGF"
    };



    // additionalData passes into this function as an object
    export const createUserProfileDocument = async (userAuth, additionalData) => { 
        if (!userAuth) return; // only perform a save if its a valid object (not null)
        // check if it already exists
    
        const userRef = firestore.doc(`users/${userAuth.uid}`);

        const snapShot = await userRef.get();

        // if it doesn't exist, create it using our userRef - don't use the snapShot
        // CRUD must be done on the docRef object
        if(!snapShot.exists){
            const { displayName, email} = userAuth;
            const createdAt = new Date(); 

            try {
                await userRef.set({
                    displayName,
                    email,
                    createdAt,
                    ...additionalData
                })
            } catch (error) {
                console.log('error creating user', error.message);
            }
        }
        return userRef;
    }

    // itemData passes into this function as an object
    export const createAmazonItemDocument = async (ASIN,affiliateUrl, comment, dateAdded, largeSSLImage, link) => { 
        //if (!itemData) return; // only perform a save if its a valid object (not null)
        // check if it already exists
    
        // Add a new document in collection "cities"
        db.collection("amazon-items").doc(`${ASIN}`).set({
            ASIN: ASIN,
            affiliateUrl: affiliateUrl,
            comment: comment,
            dateAdded: dateAdded,
            largeSSLImage: largeSSLImage,
            link: link
        })
        .then(function() {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });


        //const itemRef = db.collection("amazon-items").doc(${ASIN});
        //const snapShot = await itemRef.get();

        // if it doesn't exist, create it using our userRef - don't use the snapShot
        // CRUD must be done on the docRef object
        // if(!snapShot.exists){
        //     const createdAt = new Date(); 

        //     try {
        //         await itemRef.set({
        //             ASIN,
        //             affiliateUrl,
        //             comment,
        //             dateAdded,
        //             largeSSLImage,
        //             link
        //         })
        //     } catch (error) {
        //         console.log('error creating amazon item', error.message);
        //     }
        // }
        // return itemRef;
    }


firebase.initializeApp(config);

// when we imported firebase auth, we got access to the method .auth
export const auth = firebase.auth();

// same thing with the firestore
export const firestore = firebase.firestore();
export const db = firebase.firestore();

// now to set up our google authentication utility
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

// in case we want the whole library
export default firebase;