import React, { Suspense } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
//import { ReactComponent as Icon } from '../../assets/icons/.svg';

import { ReactComponent as IconACs } from "../../assets/icons/acs.svg";
import { ReactComponent as IconAFrames } from "../../assets/icons/a_frames.svg";
import { ReactComponent as IconAirFilters } from "../../assets/icons/air_filters.svg";
import { ReactComponent as IconAirMattresses } from "../../assets/icons/air_mattresses.svg";
import { ReactComponent as IconAirpots } from "../../assets/icons/airpots.svg";
import { ReactComponent as IconAntiques } from "../../assets/icons/antiques.svg";
import { ReactComponent as IconAprons } from "../../assets/icons/aprons.svg";
import { ReactComponent as IconArtKits } from "../../assets/icons/art_kits.svg";
import { ReactComponent as IconArtPaint } from "../../assets/icons/art_paint.svg";
import { ReactComponent as IconArtPaper } from "../../assets/icons/art_paper.svg";
import { ReactComponent as IconArtwork } from "../../assets/icons/artwork.svg";
import { ReactComponent as IconAthleticWear } from "../../assets/icons/athletic_wear.svg";
import { ReactComponent as IconBabyBottles } from "../../assets/icons/baby_bottles.svg";
import { ReactComponent as IconBabyClothes } from "../../assets/icons/baby_clothes.svg";
import { ReactComponent as IconBabyFoodMeat } from "../../assets/icons/baby_food_meat.svg";
import { ReactComponent as IconBabyWashAndLotion } from "../../assets/icons/baby_wash_and_lotion.svg";
import { ReactComponent as IconBabyWipes } from "../../assets/icons/baby_wipes.svg";
import { ReactComponent as IconBackBraces } from "../../assets/icons/back_braces.svg";
import { ReactComponent as IconBackpacks } from "../../assets/icons/backpacks.svg";
import { ReactComponent as IconBalloons } from "../../assets/icons/balloons.svg";
import { ReactComponent as IconBandShirts } from "../../assets/icons/band_shirts.svg";
import { ReactComponent as IconBarSoaps } from "../../assets/icons/bar_soaps.svg";
import { ReactComponent as IconBaseballBaps } from "../../assets/icons/baseball_caps.svg";
import { ReactComponent as IconBatteries } from "../../assets/icons/batteries.svg";
import { ReactComponent as IconBeansAndRice } from "../../assets/icons/beans_and_rice.svg";
import { ReactComponent as IconBedding } from "../../assets/icons/bedding.svg";
import { ReactComponent as IconBedFrames } from "../../assets/icons/bed_frames.svg";
import { ReactComponent as IconBicycles } from "../../assets/icons/bicycles.svg";
import { ReactComponent as IconBinders } from "../../assets/icons/binders.svg";
import { ReactComponent as IconBirdSeed } from "../../assets/icons/bird_seed.svg";
import { ReactComponent as IconBlackColoredClothing } from "../../assets/icons/black_colored_clothing.svg";
import { ReactComponent as IconBlackDresses } from "../../assets/icons/black_dresses.svg";
import { ReactComponent as IconBlackHandbags } from "../../assets/icons/black_handbags.svg";
import { ReactComponent as IconBlankets } from "../../assets/icons/blankets.svg";
import { ReactComponent as IconBleach } from "../../assets/icons/bleach.svg";
import { ReactComponent as IconBlood } from "../../assets/icons/blood.svg";
import { ReactComponent as IconBlouses } from "../../assets/icons/blouses.svg";
import { ReactComponent as IconBluetoothSpeakers } from "../../assets/icons/bluetooth_speakers.svg";
import { ReactComponent as IconBoardGames } from "../../assets/icons/board_games.svg";
import { ReactComponent as IconBodyWash } from "../../assets/icons/body_wash.svg";
import { ReactComponent as IconBooks } from "../../assets/icons/books.svg";
import { ReactComponent as IconBookshelves } from "../../assets/icons/bookshelves.svg";
import { ReactComponent as IconBottledWater } from "../../assets/icons/bottled_water.svg";
import { ReactComponent as IconBoxTrucks } from "../../assets/icons/box_trucks.svg";
import { ReactComponent as IconBubbleWrap } from "../../assets/icons/bubble_wrap.svg";
import { ReactComponent as IconBubblesChalk } from "../../assets/icons/bubbles_chalk.svg";
import { ReactComponent as IconBugSpray } from "../../assets/icons/bug_spray.svg";
import { ReactComponent as IconBusPasses } from "../../assets/icons/bus_passes.svg";
import { ReactComponent as IconButtons } from "../../assets/icons/buttons.svg";
import { ReactComponent as IconButtondowns } from "../../assets/icons/buttondowns.svg";
import { ReactComponent as IconCake } from "../../assets/icons/cake.svg";
import { ReactComponent as IconCameras } from "../../assets/icons/cameras.svg";
import { ReactComponent as IconCandyCanes } from "../../assets/icons/candy_canes.svg";
import { ReactComponent as IconCannedCheese } from "../../assets/icons/canned_cheese.svg";
import { ReactComponent as IconCannedFruit } from "../../assets/icons/canned_fruit.svg";
import { ReactComponent as IconCannedPetFood } from "../../assets/icons/canned_pet_food.svg";
import { ReactComponent as IconCannedSoup } from "../../assets/icons/canned_soup.svg";
import { ReactComponent as IconCannedTomato } from "../../assets/icons/canned_tomato.svg";
import { ReactComponent as IconCannedTuna } from "../../assets/icons/canned_tuna.svg";
import { ReactComponent as IconCannedVegetables } from "../../assets/icons/canned_vegetables.svg";
import { ReactComponent as IconCanOpeners } from "../../assets/icons/can_openers.svg";
import { ReactComponent as IconCanvases } from "../../assets/icons/canvases.svg";
import { ReactComponent as IconCards } from "../../assets/icons/cards.svg";
import { ReactComponent as IconCars } from "../../assets/icons/cars.svg";
import { ReactComponent as IconCarSeats } from "../../assets/icons/car_seats.svg";
import { ReactComponent as IconCatToys } from "../../assets/icons/cat_toys.svg";
import { ReactComponent as IconCatToysAndTrees } from "../../assets/icons/cat_toys_and_trees.svg";
import { ReactComponent as IconCatTrees } from "../../assets/icons/cat_trees.svg";
import { ReactComponent as IconCds } from "../../assets/icons/cds.svg";
import { ReactComponent as IconCedarPosts } from "../../assets/icons/cedar_posts.svg";
import { ReactComponent as IconCereal } from "../../assets/icons/cereal.svg";
import { ReactComponent as IconChildrensBooks } from "../../assets/icons/childrens_books.svg";
import { ReactComponent as IconChina } from "../../assets/icons/china.svg";
import { ReactComponent as IconChristmasDecor } from "../../assets/icons/christmas_decor.svg";
import { ReactComponent as IconChromebooks } from "../../assets/icons/chromebooks.svg";
import { ReactComponent as IconCleaningSupplies } from "../../assets/icons/cleaning_supplies.svg";
import { ReactComponent as IconCloroxWipes } from "../../assets/icons/clorox_wipes.svg";
import { ReactComponent as IconCollectibles } from "../../assets/icons/collectibles.svg";
import { ReactComponent as IconColoredPencils } from "../../assets/icons/colored_pencils.svg";
import { ReactComponent as IconColoringBooks } from "../../assets/icons/coloring_books.svg";
import { ReactComponent as IconComposters } from "../../assets/icons/composters.svg";
import { ReactComponent as IconComputerChips } from "../../assets/icons/computer_chips.svg";
import { ReactComponent as IconCondiments } from "../../assets/icons/condiments.svg";
import { ReactComponent as IconConstructionPaper } from "../../assets/icons/construction_paper.svg";
import { ReactComponent as IconCoolers } from "../../assets/icons/coolers.svg";
import { ReactComponent as IconCookies } from "../../assets/icons/cookies.svg";
import { ReactComponent as IconCosmetics } from "../../assets/icons/cosmetics.svg";
import { ReactComponent as IconCottonBalls } from "../../assets/icons/cotton_balls.svg";
import { ReactComponent as IconCrayons } from "../../assets/icons/crayons.svg";
import { ReactComponent as IconCribs } from "../../assets/icons/cribs.svg";
import { ReactComponent as IconCrosswords } from "../../assets/icons/crosswords.svg";
import { ReactComponent as IconCuttingBoards } from "../../assets/icons/cutting_boards.svg";
import { ReactComponent as IconDesignerWomens } from "../../assets/icons/designer_womens.svg";
import { ReactComponent as IconDesktops } from "../../assets/icons/desktops.svg";
import { ReactComponent as IconDiaperCream } from "../../assets/icons/diaper_cream.svg";
import { ReactComponent as IconDiapers } from "../../assets/icons/diapers.svg";
import { ReactComponent as IconDiffusers } from "../../assets/icons/diffusers.svg";
import { ReactComponent as IconDirtyLinens } from "../../assets/icons/dirty_linens.svg";
import { ReactComponent as IconDirtyClothes } from "../../assets/icons/dirty_clothes.svg";
import { ReactComponent as IconDirtyShoes } from "../../assets/icons/dirty_shoes.svg";
import { ReactComponent as IconDirtyTextiles } from "../../assets/icons/dirty_textiles.svg";
import { ReactComponent as IconDishware } from "../../assets/icons/dishware.svg";
import { ReactComponent as IconDogCrates } from "../../assets/icons/dog_crates.svg";
import { ReactComponent as IconDogHouses } from "../../assets/icons/doghouses.svg";
import { ReactComponent as IconDogFood } from "../../assets/icons/dog_food.svg";
import { ReactComponent as IconDogToys } from "../../assets/icons/dog_toys.svg";
import { ReactComponent as IconDogTreats } from "../../assets/icons/dog_treats.svg";
import { ReactComponent as IconDolls } from "../../assets/icons/dolls.svg";
import { ReactComponent as IconDollys } from "../../assets/icons/dollys.svg";
import { ReactComponent as IconDryCatFood } from "../../assets/icons/dry_cat_food.svg";
import { ReactComponent as IconDryEraseBoard } from "../../assets/icons/dryerase_board.svg";
import { ReactComponent as IconDvds } from "../../assets/icons/dvds.svg";
import { ReactComponent as IconEarbuds } from "../../assets/icons/earbuds.svg";
import { ReactComponent as IconEarlyEdTablets } from "../../assets/icons/early_ed_tablets.svg";
import { ReactComponent as IconElectronics } from "../../assets/icons/electronics.svg";
import { ReactComponent as IconEmptyNeed } from "../../assets/icons/empty_need.svg";
import { ReactComponent as IconEnvelopes } from "../../assets/icons/envelopes.svg";
import { ReactComponent as IconEveningGowns } from "../../assets/icons/evening_gowns.svg";
import { ReactComponent as IconEveningJewelry } from "../../assets/icons/evening_jewelry.svg";
import { ReactComponent as IconEveningPurses } from "../../assets/icons/evening_purses.svg";
import { ReactComponent as IconEveningShoes } from "../../assets/icons/evening_shoes.svg";
import { ReactComponent as IconExerciseEquipment } from "../../assets/icons/exercise_equipment.svg";
import { ReactComponent as IconExtensionCords } from "../../assets/icons/extension_cords.svg";
import { ReactComponent as IconFabuloso } from "../../assets/icons/fabuloso.svg";
import { ReactComponent as IconFaceWash } from "../../assets/icons/face_wash.svg";
import { ReactComponent as IconFeminineProducts } from "../../assets/icons/feminine_products.svg";
import { ReactComponent as IconFeralCages } from "../../assets/icons/feral_cages.svg";
import { ReactComponent as IconFileCabinets } from "../../assets/icons/file_cabinets.svg";
import { ReactComponent as IconFilaments } from "../../assets/icons/filaments.svg";
import { ReactComponent as IconFirstAid } from "../../assets/icons/first_aid.svg";
import { ReactComponent as IconFitnessTrackers } from "../../assets/icons/fitness_trackers.svg";
import { ReactComponent as IconFlashlights } from "../../assets/icons/flashlights.svg";
import { ReactComponent as IconFoldingTables } from "../../assets/icons/folding_tables.svg";
import { ReactComponent as IconFreshProduce } from "../../assets/icons/fresh_produce.svg";
import { ReactComponent as IconFrisbees } from "../../assets/icons/frisbees.svg";
import { ReactComponent as IconFurniture } from "../../assets/icons/furniture.svg";
import { ReactComponent as IconGamingSystems } from "../../assets/icons/gaming_systems.svg";
import { ReactComponent as IconGardeningSupplies } from "../../assets/icons/gardening_supplies.svg";
import { ReactComponent as IconGenerators } from "../../assets/icons/generators.svg";
import { ReactComponent as IconGesso } from "../../assets/icons/gesso.svg";
import { ReactComponent as IconGiftBags } from "../../assets/icons/gift_bags.svg";
import { ReactComponent as IconGiftCards } from "../../assets/icons/gift_cards.svg";
import { ReactComponent as IconGiftItems } from "../../assets/icons/gift_items.svg";
import { ReactComponent as IconGlitter } from "../../assets/icons/glitter.svg";
import { ReactComponent as IconGloves } from "../../assets/icons/gloves.svg";
import { ReactComponent as IconGlue } from "../../assets/icons/glue.svg";
import { ReactComponent as IconGoPros } from "../../assets/icons/go_pros.svg";
import { ReactComponent as IconGolfCarts } from "../../assets/icons/golf_carts.svg";
import { ReactComponent as IconGraduationGowns } from "../../assets/icons/graduation_gowns.svg";
import { ReactComponent as IconGreenies } from "../../assets/icons/greenies.svg";
import { ReactComponent as IconGrills } from "../../assets/icons/grills.svg";
import { ReactComponent as IconHairAccessories } from "../../assets/icons/hair_accessories.svg";
import { ReactComponent as IconHairbrushesCombs } from "../../assets/icons/hairbrushes_combs.svg";
import { ReactComponent as IconHairClippers } from "../../assets/icons/hair_clippers.svg";
import { ReactComponent as IconHandwarmers } from "../../assets/icons/handwarmers.svg";
import { ReactComponent as IconHatsAndGloves } from "../../assets/icons/hats_and_gloves.svg";
import { ReactComponent as IconHazardoesWaste } from "../../assets/icons/hazardous_waste.svg";
import { ReactComponent as IconHeadphones } from "../../assets/icons/headphones.svg";
import { ReactComponent as IconHealthySnacks } from "../../assets/icons/healthy_snacks.svg";
import { ReactComponent as IconHeatLamps } from "../../assets/icons/heat_lamps.svg";
import { ReactComponent as IconHolePunchers } from "../../assets/icons/hole_punchers.svg";
import { ReactComponent as IconHomeDecor } from "../../assets/icons/home_decor.svg";
import { ReactComponent as IconHomeRentalVouchers } from "../../assets/icons/home_rental_vouchers.svg";
import { ReactComponent as IconHoodies } from "../../assets/icons/hoodies.svg";
import { ReactComponent as IconHotDrink } from "../../assets/icons/hot_drink.svg";
import { ReactComponent as IconHotPads } from "../../assets/icons/hot_pads.svg";
import { ReactComponent as IconHotPlates } from "../../assets/icons/hot_plates.svg";
import { ReactComponent as IconHygieneItems } from "../../assets/icons/hygiene_items.svg";
import { ReactComponent as IconIconicAustin } from "../../assets/icons/iconic_austin.svg";
import { ReactComponent as IconInstruments } from "../../assets/icons/instruments.svg";
import { ReactComponent as IconIpods } from "../../assets/icons/ipods.svg";
import { ReactComponent as IconIvyBlock } from "../../assets/icons/ivy_block.svg";
import { ReactComponent as IconIvyWash } from "../../assets/icons/ivy_wash.svg";
import { ReactComponent as IconJackets } from "../../assets/icons/jackets.svg";
import { ReactComponent as IconJeans } from "../../assets/icons/jeans.svg";
import { ReactComponent as IconJewelry } from "../../assets/icons/jewelry.svg";
import { ReactComponent as IconJewelryMaking } from "../../assets/icons/jewelry_making.svg";
import { ReactComponent as IconJuiceBoxes } from "../../assets/icons/juice_boxes.svg";
import { ReactComponent as IconKayaks } from "../../assets/icons/kayaks.svg";
import { ReactComponent as IconKeyboards } from "../../assets/icons/keyboards.svg";
import { ReactComponent as IconKiddiePools } from "../../assets/icons/kiddie_pools.svg";
import { ReactComponent as IconKidsBelts } from "../../assets/icons/kids_belts.svg";
import { ReactComponent as IconKidsClothing } from "../../assets/icons/kids_clothing.svg";
import { ReactComponent as IconKidsPurses } from "../../assets/icons/kids_purses.svg";
import { ReactComponent as IconKidsUnderwear } from "../../assets/icons/kids_underwear.svg";
import { ReactComponent as IconKidsWatches } from "../../assets/icons/kids_watches.svg";
import { ReactComponent as IconKindNote } from "../../assets/icons/kind_note.svg";
import { ReactComponent as IconKitchenKnives } from "../../assets/icons/kitchen_knives.svg";
import { ReactComponent as IconKitchenware } from "../../assets/icons/kitchenware.svg";
import { ReactComponent as IconKmr } from "../../assets/icons/kmr.svg";
import { ReactComponent as IconKnickKnacks } from "../../assets/icons/knick_knacks.svg";
import { ReactComponent as IconKurandaBeds } from "../../assets/icons/kuranda_beds.svg";
import { ReactComponent as IconLadder } from "../../assets/icons/ladder.svg";
import { ReactComponent as IconLamps } from "../../assets/icons/lamps.svg";
import { ReactComponent as IconLaptops } from "../../assets/icons/laptops.svg";
import { ReactComponent as IconLargeEnvelopes } from "../../assets/icons/large_envelopes.svg";
import { ReactComponent as IconLaundryDetergent } from "../../assets/icons/laundry_detergent.svg";
import { ReactComponent as IconLawnmowers } from "../../assets/icons/lawnmowers.svg";
import { ReactComponent as IconLeashesAndCollars } from "../../assets/icons/leashes_and_collars.svg";
import { ReactComponent as IconLeftovers } from "../../assets/icons/leftovers.svg";
import { ReactComponent as IconLegos } from "../../assets/icons/legos.svg";
import { ReactComponent as IconLighting } from "../../assets/icons/lighting.svg";
import { ReactComponent as IconLitter } from "../../assets/icons/litter.svg";
import { ReactComponent as IconLockBoxes } from "../../assets/icons/lock_boxes.svg";
import { ReactComponent as IconLonghornGear } from "../../assets/icons/longhorn_gear.svg";
import { ReactComponent as IconMagazines } from "../../assets/icons/magazines.svg";
import { ReactComponent as IconManilaFolders } from "../../assets/icons/manila_folders.svg";
import { ReactComponent as IconMarkers } from "../../assets/icons/markers.svg";
import { ReactComponent as IconMedications } from "../../assets/icons/medications.svg";
import { ReactComponent as IconMensAccessories } from "../../assets/icons/mens_accessories.svg";
import { ReactComponent as IconMensClothing } from "../../assets/icons/mens_clothing.svg";
import { ReactComponent as IconMensPants } from "../../assets/icons/mens_pants.svg";
import { ReactComponent as IconMensProfessional } from "../../assets/icons/mens_professional.svg";
import { ReactComponent as IconMensProfessionalShoes } from "../../assets/icons/mens_professional_shoes.svg";
import { ReactComponent as IconMensUnderwear } from "../../assets/icons/mens_underwear.svg";
import { ReactComponent as IconMensWinterClothing } from "../../assets/icons/mens_winter_clothing.svg";
import { ReactComponent as IconMicrophones } from "../../assets/icons/microphones.svg";
import { ReactComponent as IconMicrowaves } from "../../assets/icons/microwaves.svg";
import { ReactComponent as IconMicrowavableMeals } from "../../assets/icons/microwavable_meals.svg";
import { ReactComponent as IconMidi } from "../../assets/icons/midi.svg";
import { ReactComponent as IconMobileWireShelving } from "../../assets/icons/mobile_wire_shelving.svg";
import { ReactComponent as IconMonoxideDetectors } from "../../assets/icons/monoxide_detectors.svg";
import { ReactComponent as IconMopsBuckets } from "../../assets/icons/mops_buckets.svg";
import { ReactComponent as IconMusicMedia } from "../../assets/icons/music_media.svg";
import { ReactComponent as IconNailClippers } from "../../assets/icons/nail_clippers.svg";
import { ReactComponent as IconNewspapers } from "../../assets/icons/newspapers.svg";
import { ReactComponent as IconNightlights } from "../../assets/icons/nighlights.svg";
import { ReactComponent as IconNotebooksNoSpiral } from "../../assets/icons/notebooks_no_spiral.svg";
import { ReactComponent as IconNotebooksWithSpiral } from "../../assets/icons/notebooks_with_spiral.svg";
import { ReactComponent as IconNursingPetBottles } from "../../assets/icons/nursing_pet_bottles.svg";
import { ReactComponent as IconNuts } from "../../assets/icons/nuts.svg";
import { ReactComponent as IconOfficeChair } from "../../assets/icons/office_chair.svg";
import { ReactComponent as IconOrnaments } from "../../assets/icons/ornaments.svg";
import { ReactComponent as IconOutdoorHeatedBeds } from "../../assets/icons/outdoor_heated_beds.svg";
import { ReactComponent as IconPacifiers } from "../../assets/icons/pacifiers.svg";
import { ReactComponent as IconPackNPlay } from "../../assets/icons/packnplay.svg";
import { ReactComponent as IconPaintingBooks } from "../../assets/icons/painting_books.svg";
import { ReactComponent as IconPajamas } from "../../assets/icons/pajamas.svg";
import { ReactComponent as IconPantryItems } from "../../assets/icons/pantry_items.svg";
import { ReactComponent as IconPaperclips } from "../../assets/icons/paperclips.svg";
import { ReactComponent as IconPaperPlates } from "../../assets/icons/paper_plates.svg";
import { ReactComponent as IconPaperTowels } from "../../assets/icons/paper_towels.svg";
import { ReactComponent as IconPasta } from "../../assets/icons/pasta.svg";
import { ReactComponent as IconPatientLifts } from "../../assets/icons/patient_lifts.svg";
import { ReactComponent as IconPbj } from "../../assets/icons/pbj.svg";
import { ReactComponent as IconPeanutButter } from "../../assets/icons/peanut_butter.svg";
import { ReactComponent as IconPeePads } from "../../assets/icons/pee_pads.svg";
import { ReactComponent as IconPencils } from "../../assets/icons/pencils.svg";
import { ReactComponent as IconPencilSharpener } from "../../assets/icons/pencil_sharpener.svg";
import { ReactComponent as IconPens } from "../../assets/icons/pens.svg";
import { ReactComponent as IconPetBeds } from "../../assets/icons/pet_beds.svg";
import { ReactComponent as IconPetBowls } from "../../assets/icons/pet_bowls.svg";
import { ReactComponent as IconPetCarriers } from "../../assets/icons/pet_carriers.svg";
import { ReactComponent as IconPetFood } from "../../assets/icons/pet_food.svg";
import { ReactComponent as IconPetHabitats } from "../../assets/icons/pet_habitats.svg";
import { ReactComponent as IconPetItems } from "../../assets/icons/pet_items.svg";
import { ReactComponent as IconPetMedications } from "../../assets/icons/pet_medications.svg";
import { ReactComponent as IconPetShampoo } from "../../assets/icons/pet_shampoo.svg";
import { ReactComponent as IconPhones } from "../../assets/icons/phones.svg";
import { ReactComponent as IconPicnicTables } from "../../assets/icons/picnic_tables.svg";
import { ReactComponent as IconPinecones } from "../../assets/icons/pinecones.svg";
import { ReactComponent as IconPizzas } from "../../assets/icons/pizzas.svg";
import { ReactComponent as IconPlants } from "../../assets/icons/plants.svg";
import { ReactComponent as IconPlasticBags } from "../../assets/icons/plastic_bags.svg";
import { ReactComponent as IconPlasticFilm } from "../../assets/icons/plastic_film.svg";
import { ReactComponent as IconPlayDoh } from "../../assets/icons/play_doh.svg";
import { ReactComponent as IconPlaygroundBalls } from "../../assets/icons/playground_balls.svg";
import { ReactComponent as IconPlaygroundTrucks } from "../../assets/icons/playground_trucks.svg";
import { ReactComponent as IconPoolNets } from "../../assets/icons/pool_nets.svg";
import { ReactComponent as IconPopUpCanopies } from "../../assets/icons/pop_up_canopies.svg";
import { ReactComponent as IconPostIts } from "../../assets/icons/postits.svg";
import { ReactComponent as IconPotsAndPans } from "../../assets/icons/pots_and_pans.svg";
import { ReactComponent as IconPowderedMilk } from "../../assets/icons/powdered_milk.svg";
import { ReactComponent as IconPpe } from "../../assets/icons/ppe.svg";
import { ReactComponent as IconPrescriptionPetFood } from "../../assets/icons/prescription_pet_food.svg";
import { ReactComponent as IconPrinter } from "../../assets/icons/printer.svg";
import { ReactComponent as IconPrinterInk } from "../../assets/icons/printer_ink.svg";
import { ReactComponent as IconPrinterPaper } from "../../assets/icons/printer_paper.svg";
import { ReactComponent as IconProjectors } from "../../assets/icons/projectors.svg";
import { ReactComponent as IconPullTabCans } from "../../assets/icons/pull_tab_cans.svg";
import { ReactComponent as IconPuppyPads } from "../../assets/icons/puppy_pads.svg";
import { ReactComponent as IconPurses } from "../../assets/icons/purses.svg";
import { ReactComponent as IconPuzzles } from "../../assets/icons/puzzles.svg";
import { ReactComponent as IconRadios } from "../../assets/icons/radios.svg";
import { ReactComponent as IconRamen } from "../../assets/icons/ramen.svg";
import { ReactComponent as IconRawhides } from "../../assets/icons/rawhides.svg";
import { ReactComponent as IconRecordPlayers } from "../../assets/icons/record_players.svg";
import { ReactComponent as IconRefrigerators } from "../../assets/icons/refrigerators.svg";
import { ReactComponent as IconReusableWaterBottles } from "../../assets/icons/reusable_water_bottles.svg";
import { ReactComponent as IconReuseableBags } from "../../assets/icons/reuseable_bags.svg";
import { ReactComponent as IconRideOns } from "../../assets/icons/ride_ons.svg";
import { ReactComponent as IconRoyalCaninBabycat } from "../../assets/icons/royal_canin_babycat.svg";
import { ReactComponent as IconRowingMachines } from "../../assets/icons/rowing_machines.svg";
import { ReactComponent as IconRubixCubes } from "../../assets/icons/rubix_cubes.svg";
import { ReactComponent as IconSafes } from "../../assets/icons/safes.svg";
import { ReactComponent as IconSafetyVests } from "../../assets/icons/safety_vests.svg";
import { ReactComponent as IconSandToys } from "../../assets/icons/sand_toys.svg";
import { ReactComponent as IconSchoolSupplies } from "../../assets/icons/school_supplies.svg";
import { ReactComponent as IconScissors } from "../../assets/icons/scissors.svg";
import { ReactComponent as IconScrapMetal } from "../../assets/icons/scrap_metal.svg";
import { ReactComponent as IconScrubs } from "../../assets/icons/scrubs.svg";
import { ReactComponent as IconSensoryToys } from "../../assets/icons/sensory_toys.svg";
import { ReactComponent as IconSewingMachines } from "../../assets/icons/sewing_machines.svg";
import { ReactComponent as IconShampooConditioner } from "../../assets/icons/shampoo_conditioner.svg";
import { ReactComponent as IconSharpies } from "../../assets/icons/sharpies.svg";
import { ReactComponent as IconShaving } from "../../assets/icons/shaving.svg";
import { ReactComponent as IconShoes } from "../../assets/icons/shoes.svg";
import { ReactComponent as IconShowerBenches } from "../../assets/icons/shower_benches.svg";
import { ReactComponent as IconShowerCurtains } from "../../assets/icons/shower_curtains.svg";
import { ReactComponent as IconSilverware } from "../../assets/icons/silverware.svg";
import { ReactComponent as IconSippyCups } from "../../assets/icons/sippy_cups.svg";
import { ReactComponent as IconSleepingBags } from "../../assets/icons/sleeping_bags.svg";
import { ReactComponent as IconSmallAppliances } from "../../assets/icons/small_appliances.svg";
import { ReactComponent as IconSneakers } from "../../assets/icons/sneakers.svg";
import { ReactComponent as IconSnuggleSafeWarmers } from "../../assets/icons/snuggle_safe_warmers.svg";
import { ReactComponent as IconSocks } from "../../assets/icons/socks.svg";
import { ReactComponent as IconSoftware } from "../../assets/icons/software.svg";
import { ReactComponent as IconSpeakers } from "../../assets/icons/speakers.svg";
import { ReactComponent as IconSporks } from "../../assets/icons/sporks.svg";
import { ReactComponent as IconStaplers } from "../../assets/icons/staplers.svg";
import { ReactComponent as IconStickers } from "../../assets/icons/stickers.svg";
import { ReactComponent as IconStoves } from "../../assets/icons/stoves.svg";
import { ReactComponent as IconStrollers } from "../../assets/icons/strollers.svg";
import { ReactComponent as IconStuffedAnimals } from "../../assets/icons/stuffed_animals.svg";
import { ReactComponent as IconStyrofoam } from "../../assets/icons/styrofoam.svg";
import { ReactComponent as IconSugar } from "../../assets/icons/sugar.svg";
import { ReactComponent as IconSunscreen } from "../../assets/icons/sunscreen.svg";
import { ReactComponent as IconSunshade } from "../../assets/icons/sunshade.svg";
import { ReactComponent as IconSweatpantsAndSweatshirts } from "../../assets/icons/sweatpants_and_sweatshirts.svg";
import { ReactComponent as IconTablets } from "../../assets/icons/tablets.svg";
import { ReactComponent as IconTape } from "../../assets/icons/tape.svg";
import { ReactComponent as IconTeensClothing } from "../../assets/icons/teens_clothing.svg";
import { ReactComponent as IconTents } from "../../assets/icons/tents.svg";
import { ReactComponent as IconThumbDrives } from "../../assets/icons/thumb_drives.svg";
import { ReactComponent as IconThumbtacks } from "../../assets/icons/thumbtacks.svg";
import { ReactComponent as IconTies } from "../../assets/icons/ties.svg";
import { ReactComponent as IconTires } from "../../assets/icons/tires.svg";
import { ReactComponent as IconTissues } from "../../assets/icons/tissues.svg";
import { ReactComponent as IconToiletPaper } from "../../assets/icons/toilet_paper.svg";
import { ReactComponent as IconTools } from "../../assets/icons/tools.svg";
import { ReactComponent as IconToothpasteToothbrushes } from "../../assets/icons/toothpaste_toothbrushes.svg";
import { ReactComponent as IconToothpicks } from "../../assets/icons/toothpicks.svg";
import { ReactComponent as IconTowels } from "../../assets/icons/towels.svg";
import { ReactComponent as IconToyBlocks } from "../../assets/icons/toy_blocks.svg";
import { ReactComponent as IconToyCars } from "../../assets/icons/toy_cars.svg";
import { ReactComponent as IconToyDrones } from "../../assets/icons/toy_drones.svg";
import { ReactComponent as IconToyRobots } from "../../assets/icons/toy_robots.svg";
import { ReactComponent as IconToys } from "../../assets/icons/toys.svg";
import { ReactComponent as IconToysAndGames } from "../../assets/icons/toys_and_games.svg";
import { ReactComponent as IconToyStrollers } from "../../assets/icons/toy_strollers.svg";
import { ReactComponent as IconToySuperheros } from "../../assets/icons/toy_superheros.svg";
import { ReactComponent as IconTrailCameras } from "../../assets/icons/trail_cameras.svg";
import { ReactComponent as IconTrash } from "../../assets/icons/trash.svg";
import { ReactComponent as IconTrashGrabbers } from "../../assets/icons/trash_grabbers.svg";
import { ReactComponent as IconTravelSupplies } from "../../assets/icons/travel_supplies.svg";
import { ReactComponent as IconTshirts } from "../../assets/icons/tshirts.svg";
import { ReactComponent as IconTupperware } from "../../assets/icons/tupperware.svg";
import { ReactComponent as IconTvs } from "../../assets/icons/tvs.svg";
import { ReactComponent as IconUndergarments } from "../../assets/icons/undergarments.svg";
import { ReactComponent as IconUtensilPacks } from "../../assets/icons/utensil_packs.svg";
import { ReactComponent as IconVacuums } from "../../assets/icons/vacuums.svg";
import { ReactComponent as IconVintageClothing } from "../../assets/icons/vintage_clothing.svg";
import { ReactComponent as IconVHS } from "../../assets/icons/vhs.svg";
import { ReactComponent as IconWalkieTalkies } from "../../assets/icons/walkie_talkies.svg";
import { ReactComponent as IconWasher } from "../../assets/icons/washer.svg";
import { ReactComponent as IconWatches } from "../../assets/icons/watches.svg";
import { ReactComponent as IconWatercolors } from "../../assets/icons/watercolors.svg";
import { ReactComponent as IconWaterHeaters } from "../../assets/icons/water_heaters.svg";
import { ReactComponent as IconWaterJar } from "../../assets/icons/water_jar.svg";
import { ReactComponent as IconWaterproofBag } from "../../assets/icons/waterproof_bag.svg";
import { ReactComponent as IconWebcams } from "../../assets/icons/webcams.svg";
import { ReactComponent as IconWeddingDresses } from "../../assets/icons/wedding_dresses.svg";
import { ReactComponent as IconWellnessWetFood } from "../../assets/icons/wellness_wet_food.svg";
import { ReactComponent as IconWetCatFood } from "../../assets/icons/wet_cat_food.svg";
import { ReactComponent as IconWheelBarrows } from "../../assets/icons/wheel_barrows.svg";
import { ReactComponent as IconWheelchairs } from "../../assets/icons/wheelchairs.svg";
import { ReactComponent as IconWildflowers } from "../../assets/icons/wildflowers.svg";
import { ReactComponent as IconWindowsAndDoors } from "../../assets/icons/windows_and_doors.svg";
import { ReactComponent as IconWoodPallets } from "../../assets/icons/wood_pallets.svg";
import { ReactComponent as IconWomensClothing } from "../../assets/icons/womens_clothing.svg";
import { ReactComponent as IconWomensProfessionalShoes } from "../../assets/icons/womens_professional_shoes.svg";
import { ReactComponent as IconWomensUnderwear } from "../../assets/icons/womens_underwear.svg";
import { ReactComponent as IconWorkBoots } from "../../assets/icons/work_boots.svg";
import { ReactComponent as IconYarn } from "../../assets/icons/yarn.svg";
import { ReactComponent as IconZipocBags } from "../../assets/icons/ziploc_bags.svg";
// UI ASSESTS
import { ReactComponent as IconAquaCheckmark } from "../../assets/ui-icons/aqua_checkmark.svg";
import { ReactComponent as IconPickup } from "../../assets/ui-icons/pickup.svg";

import { Row, Col } from "react-bootstrap";
import "./card.styles.scss";
// this is a new functional component that returns a div
// we wouldn't have access to needItem in the scope now, so pass it through the props
// <img alt="needItem" src={ 'https://robohash.org/' + props.needItem.id + '?set=set2&size=180x180' } />
// var IconComponent = Components["IconJackets"];

export default class Card extends React.Component {
  render() {
    //ALL ICON NAMES
    const components = {
      acs: IconACs,
      a_frames: IconAFrames,
      air_filters: IconAirFilters,
      air_mattresses: IconAirMattresses,
      airpots: IconAirpots,
      antiques: IconAntiques,
      aprons: IconAprons,
      art_kits: IconArtKits,
      art_paint: IconArtPaint,
      art_paper: IconArtPaper,
      artwork: IconArtwork,
      athletic_wear: IconAthleticWear,
      baby_bottles: IconBabyBottles,
      baby_clothes: IconBabyClothes,
      baby_food_meat: IconBabyFoodMeat,
      baby_wash_and_lotion: IconBabyWashAndLotion,
      baby_wipes: IconBabyWipes,
      back_braces: IconBackBraces,
      backpacks: IconBackpacks,
      balloons: IconBalloons,
      band_shirts: IconBandShirts,
      bar_soaps: IconBarSoaps,
      baseball_caps: IconBaseballBaps,
      batteries: IconBatteries,
      beans_and_rice: IconBeansAndRice,
      bedding: IconBedding,
      bed_frames: IconBedFrames,
      bicycles: IconBicycles,
      binders: IconBinders,
      bird_seed: IconBirdSeed,
      black_colored_clothing: IconBlackColoredClothing,
      black_dresses: IconBlackDresses,
      black_handbags: IconBlackHandbags,
      blankets: IconBlankets,
      bleach: IconBleach,
      blood: IconBlood,
      blouses: IconBlouses,
      bluetooth_speakers: IconBluetoothSpeakers,
      board_games: IconBoardGames,
      body_wash: IconBodyWash,
      books: IconBooks,
      bookshelves: IconBookshelves,
      bottled_water: IconBottledWater,
      box_trucks: IconBoxTrucks,
      bubble_wrap: IconBubbleWrap,
      bubbles_chalk: IconBubblesChalk,
      bug_spray: IconBugSpray,
      buttons: IconButtons,
      buttondowns: IconButtondowns,
      bus_passes: IconBusPasses,
      cake: IconCake,
      cameras: IconCameras,
      can_openers: IconCanOpeners,
      candy_canes: IconCandyCanes,
      canned_cheese: IconCannedCheese,
      canned_fruit: IconCannedFruit,
      canned_pet_food: IconCannedPetFood,
      canned_soup: IconCannedSoup,
      canned_tomato: IconCannedTomato,
      canned_tuna: IconCannedTuna,
      canned_vegetables: IconCannedVegetables,
      canvases: IconCanvases,
      cards: IconCards,
      cars: IconCars,
      car_seats: IconCarSeats,
      cat_toys_and_trees: IconCatToysAndTrees,
      cat_toys: IconCatToys,
      cat_trees: IconCatTrees,
      cds: IconCds,
      cedar_posts: IconCedarPosts,
      cereal: IconCereal,
      childrens_books: IconChildrensBooks,
      china: IconChina,
      christmas_decor: IconChristmasDecor,
      chromebooks: IconChromebooks,
      cleaning_supplies: IconCleaningSupplies,
      clorox_wipes: IconCloroxWipes,
      collectibles: IconCollectibles,
      colored_pencils: IconColoredPencils,
      coloring_books: IconColoringBooks,
      composters: IconComposters,
      computer_chips: IconComputerChips,
      condiments: IconCondiments,
      construction_paper: IconConstructionPaper,
      coolers: IconCoolers,
      cookies: IconCookies,
      cosmetics: IconCosmetics,
      cotton_balls: IconCottonBalls,
      crayons: IconCrayons,
      cribs: IconCribs,
      crosswords: IconCrosswords,
      cutting_boards: IconCuttingBoards,
      desktops: IconDesktops,
      designer_womens: IconDesignerWomens,
      diaper_cream: IconDiaperCream,
      diapers: IconDiapers,
      diffusers: IconDiffusers,
      dirty_linens: IconDirtyLinens,
      dirty_clothes: IconDirtyClothes,
      dirty_shoes: IconDirtyShoes,
      dirty_textiles: IconDirtyTextiles,
      dishware: IconDishware,
      dog_crates: IconDogCrates,
      dog_houses: IconDogHouses,
      dog_food: IconDogFood,
      dog_toys: IconDogToys,
      dog_treats: IconDogTreats,
      dolls: IconDolls,
      dollys: IconDollys,
      dry_cat_food: IconDryCatFood,
      dryerase_board: IconDryEraseBoard,
      dvds: IconDvds,
      earbuds: IconEarbuds,
      early_ed_tablets: IconEarlyEdTablets,
      electronics: IconElectronics,
      empty_need: IconEmptyNeed,
      envelopes: IconEnvelopes,
      evening_gowns: IconEveningGowns,
      evening_jewelry: IconEveningJewelry,
      evening_purses: IconEveningPurses,
      evening_shoes: IconEveningShoes,
      exercise_equipment: IconExerciseEquipment,
      extension_cords: IconExtensionCords,
      fabuloso: IconFabuloso,
      face_wash: IconFaceWash,
      feminine_products: IconFeminineProducts,
      feral_cages: IconFeralCages,
      filaments: IconFilaments,
      file_cabinets: IconFileCabinets,
      first_aid: IconFirstAid,
      fitness_trackers: IconFitnessTrackers,
      flashlights: IconFlashlights,
      folding_tables: IconFoldingTables,
      fresh_produce: IconFreshProduce,
      frisbees: IconFrisbees,
      furniture: IconFurniture,
      gaming_systems: IconGamingSystems,
      gardening_supplies: IconGardeningSupplies,
      generators: IconGenerators,
      gesso: IconGesso,
      gift_bags: IconGiftBags,
      gift_cards: IconGiftCards,
      gift_items: IconGiftItems,
      glitter: IconGlitter,
      gloves: IconGloves,
      glue: IconGlue,
      go_pros: IconGoPros,
      golf_carts: IconGolfCarts,
      gradutaion_gowns: IconGraduationGowns,
      greenies: IconGreenies,
      grills: IconGrills,
      hair_accessories: IconHairAccessories,
      hairbrushes_combs: IconHairbrushesCombs,
      hair_clippers: IconHairClippers,
      handwarmers: IconHandwarmers,
      hats_and_gloves: IconHatsAndGloves,
      hazardous_waste: IconHazardoesWaste,
      headphones: IconHeadphones,
      healthy_snacks: IconHealthySnacks,
      heat_lamps: IconHeatLamps,
      hole_punchers: IconHolePunchers,
      home_decor: IconHomeDecor,
      home_rental_vouchers: IconHomeRentalVouchers,
      hoodies: IconHoodies,
      hot_drink: IconHotDrink,
      hot_pads: IconHotPads,
      hot_plates: IconHotPlates,
      hygiene_items: IconHygieneItems,
      iconic_austin: IconIconicAustin,
      instruments: IconInstruments,
      ipods: IconIpods,
      ivy_block: IconIvyBlock,
      ivy_wash: IconIvyWash,
      jackets: IconJackets,
      jeans: IconJeans,
      jewelry: IconJewelry,
      jewelry_making: IconJewelryMaking,
      juice_boxes: IconJuiceBoxes,
      kayaks: IconKayaks,
      keyboards: IconKeyboards,
      kiddie_pools: IconKiddiePools,
      kids_belts: IconKidsBelts,
      kids_clothing: IconKidsClothing,
      kids_purses: IconKidsPurses,
      kids_underwear: IconKidsUnderwear,
      kids_watches: IconKidsWatches,
      kind_note: IconKindNote,
      kitchen_knives: IconKitchenKnives,
      kitchenware: IconKitchenware,
      kmr: IconKmr,
      knick_knacks: IconKnickKnacks,
      kuranda_beds: IconKurandaBeds,
      ladder: IconLadder,
      lamps: IconLamps,
      laptops: IconLaptops,
      large_envelopes: IconLargeEnvelopes,
      laundry_detergent: IconLaundryDetergent,
      lawnmowers: IconLawnmowers,
      leashes_and_collars: IconLeashesAndCollars,
      leftovers: IconLeftovers,
      legos: IconLegos,
      lighting: IconLighting,
      litter: IconLitter,
      lock_boxes: IconLockBoxes,
      longhorn_gear: IconLonghornGear,
      magazines: IconMagazines,
      manila_folders: IconManilaFolders,
      markers: IconMarkers,
      medications: IconMedications,
      mens_accessories: IconMensAccessories,
      mens_clothing: IconMensClothing,
      mens_pants: IconMensPants,
      mens_professional_shoes: IconMensProfessionalShoes,
      mens_professional: IconMensProfessional,
      mens_underwear: IconMensUnderwear,
      mens_winter_clothing: IconMensWinterClothing,
      microphones: IconMicrophones,
      microwavable_meals: IconMicrowavableMeals,
      microwaves: IconMicrowaves,
      midi: IconMidi,
      mobile_wire_shelving: IconMobileWireShelving,
      monoxide_detectors: IconMonoxideDetectors,
      mops_buckets: IconMopsBuckets,
      music_media: IconMusicMedia,
      nail_clippers: IconNailClippers,
      newspapers: IconNewspapers,
      nighlights: IconNightlights,
      notebooks_no_spiral: IconNotebooksNoSpiral,
      notebooks_with_spiral: IconNotebooksWithSpiral,
      nursing_pet_bottles: IconNursingPetBottles,
      nuts: IconNuts,
      office_chair: IconOfficeChair,
      ornaments: IconOrnaments,
      outdoor_heated_beds: IconOutdoorHeatedBeds,
      pacifiers: IconPacifiers,
      packnplay: IconPackNPlay,
      painting_books: IconPaintingBooks,
      pajamas: IconPajamas,
      pantry_items: IconPantryItems,
      paperclips: IconPaperclips,
      paper_plates: IconPaperPlates,
      paper_towels: IconPaperTowels,
      pasta: IconPasta,
      patient_lifts: IconPatientLifts,
      pbj: IconPbj,
      peanut_butter: IconPeanutButter,
      pee_pads: IconPeePads,
      pencil_sharpener: IconPencilSharpener,
      pencils: IconPencils,
      pens: IconPens,
      pet_beds: IconPetBeds,
      pet_bowls: IconPetBowls,
      pet_carriers: IconPetCarriers,
      pet_food: IconPetFood,
      pet_habitats: IconPetHabitats,
      pet_items: IconPetItems,
      pet_medications: IconPetMedications,
      pet_shampoo: IconPetShampoo,
      phones: IconPhones,
      picnic_tables: IconPicnicTables,
      pinecones: IconPinecones,
      pizzas: IconPizzas,
      plants: IconPlants,
      plastic_bags: IconPlasticBags,
      plastic_film: IconPlasticFilm,
      play_doh: IconPlayDoh,
      playground_balls: IconPlaygroundBalls,
      playground_trucks: IconPlaygroundTrucks,
      pool_nets: IconPoolNets,
      pop_up_canopies: IconPopUpCanopies,
      postits: IconPostIts,
      pots_and_pans: IconPotsAndPans,
      powdered_milk: IconPowderedMilk,
      ppe: IconPpe,
      prescription_pet_food: IconPrescriptionPetFood,
      printer_ink: IconPrinterInk,
      printer_paper: IconPrinterPaper,
      printer: IconPrinter,
      projectors: IconProjectors,
      pull_tab_cans: IconPullTabCans,
      puppy_pads: IconPuppyPads,
      purses: IconPurses,
      puzzles: IconPuzzles,
      radios: IconRadios,
      ramen: IconRamen,
      rawhides: IconRawhides,
      record_players: IconRecordPlayers,
      refrigerators: IconRefrigerators,
      reuseable_bags: IconReuseableBags,
      reuseable_water_bottles: IconReusableWaterBottles,
      ride_ons: IconRideOns,
      royal_canin_babycat: IconRoyalCaninBabycat,
      rowing_machines: IconRowingMachines,
      rubix_cubes: IconRubixCubes,
      safes: IconSafes,
      safety_vests: IconSafetyVests,
      sand_toys: IconSandToys,
      school_supplies: IconSchoolSupplies,
      scissors: IconScissors,
      scrap_metal: IconScrapMetal,
      scrubs: IconScrubs,
      sensory_toys: IconSensoryToys,
      sewing_machines: IconSewingMachines,
      shampoo_conditioner: IconShampooConditioner,
      sharpies: IconSharpies,
      shaving: IconShaving,
      shoes: IconShoes,
      shower_benches: IconShowerBenches,
      shower_curtains: IconShowerCurtains,
      silverware: IconSilverware,
      sippy_cups: IconSippyCups,
      sleeping_bags: IconSleepingBags,
      small_appliances: IconSmallAppliances,
      sneakers: IconSneakers,
      snuggle_safe_warmers: IconSnuggleSafeWarmers,
      socks: IconSocks,
      software: IconSoftware,
      speakers: IconSpeakers,
      sporks: IconSporks,
      staplers: IconStaplers,
      stickers: IconStickers,
      stoves: IconStoves,
      strollers: IconStrollers,
      stuffed_animals: IconStuffedAnimals,
      styrofoam: IconStyrofoam,
      sugar: IconSugar,
      sunscreen: IconSunscreen,
      sunshade: IconSunshade,
      sweatpants_and_sweatshirts: IconSweatpantsAndSweatshirts,
      tablets: IconTablets,
      tape: IconTape,
      teens_clothing: IconTeensClothing,
      tents: IconTents,
      thumb_drives: IconThumbDrives,
      thumbtacks: IconThumbtacks,
      ties: IconTies,
      tires: IconTires,
      tissues: IconTissues,
      toilet_paper: IconToiletPaper,
      tools: IconTools,
      toothpaste_toothbrushes: IconToothpasteToothbrushes,
      toothpicks: IconToothpicks,
      towels: IconTowels,
      toy_blocks: IconToyBlocks,
      toy_cars: IconToyCars,
      toy_drones: IconToyDrones,
      toy_robots: IconToyRobots,
      toys: IconToys,
      toys_and_games: IconToysAndGames,
      toy_strollers: IconToyStrollers,
      toy_superheros: IconToySuperheros,
      trail_cameras: IconTrailCameras,
      trash_grabbers: IconTrashGrabbers,
      trash: IconTrash,
      travel_supplies: IconTravelSupplies,
      tshirts: IconTshirts,
      tupperware: IconTupperware,
      tvs: IconTvs,
      undergarments: IconUndergarments,
      utensil_packs: IconUtensilPacks,
      vacuums: IconVacuums,
      vhs: IconVHS,
      vintage_clothing: IconVintageClothing,
      walkie_talkies: IconWalkieTalkies,
      washer: IconWasher,
      watches: IconWatches,
      water_jar: IconWaterJar,
      water_heaters: IconWaterHeaters,
      watercolors: IconWatercolors,
      waterproof_bag: IconWaterproofBag,
      webcams: IconWebcams,
      wedding_dresses: IconWeddingDresses,
      wellness_wet_food: IconWellnessWetFood,
      wet_cat_food: IconWetCatFood,
      wheel_barrows: IconWheelBarrows,
      wheelchairs: IconWheelchairs,
      wildflowers: IconWildflowers,
      windows_and_doors: IconWindowsAndDoors,
      wood_pallets: IconWoodPallets,
      womens_clothing: IconWomensClothing,
      womens_professional_shoes: IconWomensProfessionalShoes,
      womens_underwear: IconWomensUnderwear,
      work_boots: IconWorkBoots,
      yarn: IconYarn,
      ziploc_bags: IconZipocBags,
    };

    let OffersPickupBanner;

    if (this.props.needItem.offersPickup) {
      OffersPickupBanner = (
        <div className="pickup-banner-bar">
          <div>
            <IconAquaCheckmark width="12" height="12" />
            <span className="pickup-text">offers pick-up</span>
            <IconPickup width="36" height="36" />
          </div>
        </div>
      );
    }

    const IconComponent = components[this.props.needItem.iconName]
      ? components[this.props.needItem.iconName]
      : IconEmptyNeed;

    const charityUrlParam = this.props.needItem.charityName.replace(/\s+/g, "");

    const CharityLogoImage = React.lazy(() =>
      import("../charity-logo-image/charity-logo-image.component")
    );

    return (
      <Col
        xs={6}
        md={4}
        lg={3}
        xl={2}
        className="card-container"
        key={this.props.needItem.key}
      >
        <div className="card">
          <Row>
            <Col className="icon-container">
              <div className="icon-container-inner-wrapper">
                <div>
                  <IconComponent
                    className="illustrated-icon"
                    width="86"
                    height="86"
                    fill="#DF9F56"
                  />
                </div>
                <h2>{this.props.needItem.itemName.toLowerCase()}</h2>

                {this.props.needItem.description && (
                  <div className="item-description-container">
                    <div className="item-description">
                      {this.props.needItem.description}{" "}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Link
            to={{ pathname: `/charity/${charityUrlParam}` }}
            className={"details-page-link"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <Row className="needed-by-container">
              <Col xs={12} className="pickup-banner-col">
                {OffersPickupBanner}
              </Col>
              <Col className="charity-logo-container" xs={4} sm={5}>
                <div>
                  <Suspense fallback={<div> ... </div>}>
                    <CharityLogoImage
                      srcCharityLogoImage={
                        this.props.needItem.charityLogoImgUrl
                      }
                    />
                  </Suspense>
                </div>
              </Col>
              <Col className="charity-facts-container" xs={8} sm={7}>
                <div className="charity-facts-container-inner-wrapper">
                  <span className="charity-facts-text-container">
                    <p className="needed-by-text">needed by:</p>
                    <p className="charity-name">
                      {" "}
                      {this.props.needItem.charityName}{" "}
                    </p>
                    {/* <p className="charity-website"> { this.props.needItem.charityWebsite } </p> */}
                  </span>
                </div>
              </Col>
            </Row>
          </Link>
          <Row className="donation-details-button-container">
            <Col>
              <Link
                to={{ pathname: `/charity/${charityUrlParam}` }}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                <button type="button" className="donation-details-button">
                  Donation details →
                </button>
              </Link>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

// var IconName = props.needItem.icon;
// var IconComponent = "Icon" + IconName;

// export const Card = props => (

//     <div className='card-container'>
//         <h2>
//             { props.needItem.name }
//         </h2>
//         <p> { props.needItem.charity } </p>
//         <p> { props.needItem.website } </p>
//     </div>
// );
