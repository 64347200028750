import React from "react";

import { Row, Col, Card, Accordion, Badge, Button } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import "./charity-card.styles.scss";
import { db } from "../../firebase/firebase.utils";
import ThumbnailNeedsItem from "../thumbnail-needs-item/thumbnail-needs-item.component";
import ThumbnailWishlistItem from "../thumbnail-wishlist-item/thumbnail-wishlist-item.component";
import { ReactComponent as AmznWishlistIcon } from "../../assets/ui-icons/amzn-wishlist.svg";
import { ReactComponent as ChewyWishlistIcon } from "../../assets/ui-icons/chewy-wishlist.svg";
import { ReactComponent as WalmartWishlistIcon } from "../../assets/ui-icons/walmart-wishlist.svg";
import { ReactComponent as TargetWishlistIcon } from "../../assets/ui-icons/target-wishlist.svg";
import { ReactComponent as ItemNeedsIcon } from "../../assets/ui-icons/item-needs.svg";
import { ReactComponent as WishlistNeedsIcon } from "../../assets/ui-icons/wishlist-needs.svg";
import { ReactComponent as ItemHeartIcon } from "../../assets/ui-icons/item-heart.svg";
import { ReactComponent as WishlistGiftIcon } from "../../assets/ui-icons/wishlist-gift.svg";
import { ReactComponent as StorefrontIcon } from "../../assets/ui-icons/storefront.svg";
import { ReactComponent as CopyToClipboardIcon } from "../../assets/ui-icons/copytoclipboard.svg";
import { ReactComponent as OrgTypeBusinessIcon } from "../../assets/ui-icons/org-type-business.svg";
import { ReactComponent as OrgTypeNonprofitIcon } from "../../assets/ui-icons/org-type-nonprofit.svg";
import { ReactComponent as OrgTypeGovernmentIcon } from "../../assets/ui-icons/org-type-government.svg";
import { ReactComponent as OrgTypeCommunityEffortIcon } from "../../assets/ui-icons/org-type-community-effort.svg";
import { ReactComponent as IconPickup } from "../../assets/ui-icons/pickup.svg";
import { ReactComponent as IconPorchPickUp } from "../../assets/ui-icons/porch-pick-up.svg";

// this is a new functional component that returns a div
// we wouldn't have access to needItem in the scope now, so pass it through the props
// <img alt="needItem" src={ 'https://robohash.org/' + props.needItem.id + '?set=set2&size=180x180' } />
// var IconComponent = Components["IconJackets"];

export default class CharityCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      charityId: this.charityId,
      // should include the wishlists and items
      charity: this.charity,
      charityName: this.charityName,
      charityMission: this.missionStatement,
      donationDetailsLink: this.donationDetailsLink,
      donationInstructions: this.donationInstructions,
      donationCoordinationEmail: this.donationCoordinationEmail,
      donationCoordinationName: this.donationCoordinationName,
      donationFormUrl: this.donationFormUrl,
      donationAddress: this.donationAddress,
      donationAddress2: this.donationAddress2,
      donationAddress3: this.donationAddress3,
      donationAddress4: this.donationAddress4,
      donationAddress5: this.donationAddress5,
      donationAddress6: this.donationAddress6,
      donationAddressMapLink: this.donationAddressMapLink,
      donationGoogleLocation: this.donationGoogleLocation,
      charityNeedItems: [],
      charityAmazonWishlists: [],
      walmartRegistries: [],
      walmartRegistryId: "",
      walmartRegistryUrl: "",
      walmartRegistryItems: [],
      orgType: this.orgType,
      offersPickup: this.props.offersPickup,
      weCannotPickup: this.props.weCannotPickup ? true : false,
      firstAmazonWishlistId: "",
      firstAmazonWishlistUrl: "",
      firstAmazonWishlistItems: [],
    };
  }

  toggleBox = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  componentDidMount() {
    // life cycle method
    // GET NEED ITEMS and set to array for this charity's property of state
    // REDO THIS METHOD FIRST!! TOO MANY QUERIES
    // TO ACCESS, CALL STATE NOT PROPS
    // because this method is called after the thumbnail list component mounts, we can't access this in the list yet

    // query charities and set the subcollection equal to the state property
    // IF WE NEST THE NEEDS ITEMS UNDER
    let firstAmazonWishlistId = "";
    let walmartRegistryId = "";

    db.collection("charities")
      .doc(this.props.charityId)
      .collection("needsitems")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        if (data) {
          this.setState({ charityNeedItems: data });
        }
      });

    // grab the walmart registry and wishlist items
    // IF WE NEST THE NEEDS ITEMS UNDER
    //if (this.props.hasWalmartRegistry){
    // db.collection("charities")
    //   .doc(this.props.charityId)
    //   .collection("walmart-registry")
    //   .get()
    //   .then((querySnapshot) => {
    //     const data = querySnapshot.docs.map((doc) => doc.data());
    //     if (data) {
    //       this.setState({ walmartRegistries: data });
    //       if (data[0]) {
    //         if (data[0].registryId) {
    //           this.setState({ walmartRegistryId: data[0].registryId });
    //           this.setState({ walmartRegistryUrl: data[0].registryUrl });
    //           walmartRegistryId = data[0].registryId;
    //         }
    //       }

    //       if (walmartRegistryId.length > 0) {
    //         db.collection("charities")
    //           .doc(this.props.charityId)
    //           .collection("walmart-registry")
    //           .doc(walmartRegistryId)
    //           .collection("items")
    //           .limit(24)
    //           .get()
    //           .then((querySnapshot) => {
    //             const data = querySnapshot.docs.map((doc) => doc.data());
    //             if (data) {
    //               this.setState({ walmartRegistryItems: data });
    //             }
    //           });
    //       }
    //     }
    //   });
    //}

    // query each charity to get their amazon wishlists and set the subcollection equal to the state property
    // IF WE NEST THE NEEDS ITEMS UNDER
    db.collection("charities")
      .doc(this.props.charityId)
      .collection("amazon-wishlists")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        if (data) {
          this.setState({ charityAmazonWishlists: data });
          if (data[0]) {
            if (data[0].wishlistId) {
              this.setState({ firstAmazonWishlistId: data[0].wishlistId });
              firstAmazonWishlistId = data[0].wishlistId;
              this.setState({ firstAmazonWishlistUrl: data[0].wishlistUrl });
            }
          }

          if (firstAmazonWishlistId.length > 0) {
            db.collection("charities")
              .doc(this.props.charityId)
              .collection("amazon-wishlists")
              .doc(firstAmazonWishlistId)
              .collection("items")
              .orderBy("priority")
              .limit(24)
              .get()
              .then((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => doc.data());
                if (data) {
                  this.setState({ firstAmazonWishlistItems: data });
                }
              });
          }
        }
      });
  }

  render() {
    const charityNeedItems = this.state.charityNeedItems;
    const charityAmazonWishlists = this.state.charityAmazonWishlists;
    const numNeedItems = charityNeedItems.length;
    const numAmazonWishlists = charityAmazonWishlists.length;
    const charityUrlParam = this.props.charity.name.replace(/\s+/g, "");
    const charityName = this.props.charity.name;
    const donationCoordinationEmail = this.props.charity
      .donationCoordinationEmail;
    let ButtonWithTopPopover;

    let firstAmazonWishlistItems = this.state.firstAmazonWishlistItems;
    let firstAmazonWishlistId = this.state.firstAmazonWishlistId;

    let NeedList;
    let OrgTypeBadge;
    let AdditionalAmazonWishlistButtons;
    let FirstWishlistAmazonItems; //from first amazon wishlist only
    let FirstAmazonWishlistButton; //from first amazon wishlist only
    const OffersPickup = this.props.offersPickup;
    let OffersPickupIconGroup;

    ButtonWithTopPopover = (buttonText, copyingText) => (
      <OverlayTrigger
        trigger="click"
        key="top"
        placement="top"
        animationConfig={{ duration: 0 }}
        overlay={
          <Popover id={`popover-positioned-top`} className="top-popover">
            <Popover.Content>copied!</Popover.Content>
          </Popover>
        }
      >
        <Button
          variant="light"
          size="sm"
          className="copy-text-button"
          onClick={() => {
            navigator.clipboard.writeText(copyingText);
          }}
        >
          <CopyToClipboardIcon
            className="copy-to-clipboard-icon"
            width="10px"
          ></CopyToClipboardIcon>
          &nbsp; {buttonText}
        </Button>
      </OverlayTrigger>
    );

    if (charityNeedItems) {
      // iterates through each needed item and generates the tiny thumbnail
      NeedList = charityNeedItems.map((item) => {
        return (
          <Col
            className="cols-for-tiles"
            key={item.itemName + this.props.charity.id + item.description}
          >
            <ThumbnailNeedsItem
              itemName={item.itemName}
              itemDescription={item.description}
              iconName={item.iconName}
            ></ThumbnailNeedsItem>
          </Col>
        );
      });

      // IF WE USED A SIMPLE LOOP
      //NeedsList = charityNeedItems.map(item => {return <ThumbnailNeedsItem key={item.itemName + item.charityId} item={item} itemName={item.itemName}/> ;});
    } else {
      NeedList = <li>0 items</li>;
    }

    if (OffersPickup) {
      OffersPickupIconGroup = (
        <span className="want-count-text">
          <IconPickup width="36" height="36" className="donated-thing-icon" />
          offers pick-up
        </span>
      );
    }

    // BUILD THE CUSTOM BADGE
    if (this.props.charity.orgType === "nonprofit") {
      OrgTypeBadge = (
        <span className="org-type-badge-and-icon">
          <OrgTypeNonprofitIcon
            className="org-type-icon"
            width="32px"
          ></OrgTypeNonprofitIcon>
          <Badge
            variant="secondary"
            className="org-type-badge org-type-nonprofit"
          >
            verified {this.props.charity.orgType}
          </Badge>
        </span>
      );
    } else if (this.props.charity.orgType === "community effort") {
      OrgTypeBadge = (
        <span className="org-type-badge-and-icon">
          <OrgTypeCommunityEffortIcon
            className="org-type-icon"
            width="32px"
          ></OrgTypeCommunityEffortIcon>
          <Badge
            variant="secondary"
            className="org-type-badge org-type-community-effort"
          >
            {this.props.charity.orgType}
          </Badge>
        </span>
      );
    } else if (this.props.charity.orgType === "city of Austin") {
      OrgTypeBadge = (
        <span className="org-type-badge-and-icon">
          <OrgTypeGovernmentIcon
            className="org-type-icon"
            width="32px"
          ></OrgTypeGovernmentIcon>
          <Badge
            variant="secondary"
            className="org-type-badge org-type-government"
          >
            {this.props.charity.orgType}
          </Badge>
        </span>
      );
    } else {
      OrgTypeBadge = (
        <span className="org-type-badge-and-icon">
          <OrgTypeBusinessIcon
            className="org-type-icon"
            width="32px"
          ></OrgTypeBusinessIcon>
          <Badge
            variant="secondary"
            className="org-type-badge org-type-business"
          >
            {this.props.charity.orgType}
          </Badge>
        </span>
      );
    }

    // THIS CREATES THE AMAZON SECTION CONTENT
    if (charityAmazonWishlists) {
      if (firstAmazonWishlistItems) {
        // iterates through each needed item and generates the amazon wishlist link button

        // creates items thumbnails for the first amazon wishlist
        FirstWishlistAmazonItems = firstAmazonWishlistItems.map((item) => {
          return (
            <Col
              xs={5}
              sm={4}
              md={4}
              lg={3}
              xl={2}
              className="cols-for-wishlist-tiles"
              key={
                item.asin +
                firstAmazonWishlistId +
                this.props.charity.id +
                charityName +
                item.title
              }
            >
              <ThumbnailWishlistItem
                itemLink={item.productlink}
                itemTitle={item.title.substring(0, 50)}
                itemImg={item.imglink}
                itemPrice={item.price}
                wishlistUrl={this.state.firstAmazonWishlistUrl}
              ></ThumbnailWishlistItem>
            </Col>
          );
        });
      }

      // builds additional wishlist link buttons not incl first wishlist
      FirstAmazonWishlistButton = charityAmazonWishlists
        .slice(0, 1) //only does first wishlist
        .map((list) => {
          return (
            <Col
              className="text-center"
              sm={12}
              md={12}
              lg={12}
              key={list.wishlistUrl + this.props.charity.id}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={list.wishlistUrl}
                className="wishlist-link"
              >
                <Button variant="light" className="wishlist-button">
                  <h4 className="wishlist-title">
                    <AmznWishlistIcon
                      className="amzn-icon wishlist-icon"
                      width="30px"
                    ></AmznWishlistIcon>
                    {list.wishlistName}
                  </h4>
                </Button>
              </a>
            </Col>
          );
        });

      // builds additional wishlist link buttons not incl first wishlist
      AdditionalAmazonWishlistButtons = charityAmazonWishlists
        .slice(1) //skips the first wishlist which gets displayed with its items
        .map((list) => {
          return (
            <Col
              className="text-center"
              sm={12}
              md={12}
              lg={12}
              key={list.wishlistUrl + this.props.charity.id}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={list.wishlistUrl}
                className="wishlist-link"
              >
                <Button variant="light" className="wishlist-button">
                  <h4 className="wishlist-title">
                    <AmznWishlistIcon
                      className="amzn-icon wishlist-icon"
                      width="30px"
                    ></AmznWishlistIcon>
                    {list.wishlistName}
                  </h4>
                </Button>
              </a>
            </Col>
          );
        });
    }

    return (
      <Router>
        <Col
          sm={10}
          md={10}
          lg={8}
          className="charity-card-container"
          key={this.props.charity.id}
        >
          <Row className="charity-card">
            <Col md={4} lg={4} className="charity-summary-container">
              <img
                className="charity-logo"
                src={this.props.charity.logoImgUrl}
                width="130px"
                alt="charity logo"
              ></img>
              <div className="org-type-container">{OrgTypeBadge}</div>
            </Col>
            <Col md={8} lg={8} className="charity-text-area">
              <h2>{charityName}</h2>
              <div className="mission-statement">
                <span>{this.props.charity.missionStatement} </span>
              </div>
              <p className="charity-website">
                <a
                  href={this.props.charity.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.charity.websiteName}
                </a>
              </p>
              {/* <Badge variant="secondary" className="charity-category-badge">
                {this.props.charity.category}
              </Badge> */}
            </Col>
            <Col md={12} className="items-accordion-container">
              <Accordion width="100%">
                <Card>
                  <Link
                    to={{ pathname: `/charity/${charityUrlParam}` }}
                    className="accordion-trigger-link"
                  >
                    <Accordion.Toggle as={Card.Header} to="/name" eventKey="0">
                      {numNeedItems === 1 && (
                        <span className="want-count-text">
                          <ItemHeartIcon
                            className="donated-thing-icon"
                            width="22px"
                          ></ItemHeartIcon>
                          {numNeedItems} need
                        </span>
                      )}
                      {numNeedItems > 1 && (
                        <span className="want-count-text">
                          <ItemHeartIcon
                            className="donated-thing-icon"
                            width="22px"
                          ></ItemHeartIcon>
                          {numNeedItems} needs
                        </span>
                      )}
                      {OffersPickupIconGroup}
                      {numAmazonWishlists +
                        (this.props.charity.chewyWishlistUrl ? 1 : 0) ===
                        1 && (
                        <span className="want-count-text">
                          &nbsp;
                          <WishlistGiftIcon
                            className="donated-thing-icon"
                            width="22px"
                          ></WishlistGiftIcon>
                          {numAmazonWishlists +
                            (this.props.charity.chewyWishlistUrl ? 1 : 0)}{" "}
                          wishlist
                        </span>
                      )}
                      {numAmazonWishlists +
                        (this.props.charity.chewyWishlistUrl ? 1 : 0) >
                        1 && (
                        <span className="want-count-text">
                          &nbsp;
                          <WishlistGiftIcon
                            className="donated-thing-icon"
                            width="22px"
                          ></WishlistGiftIcon>
                          {numAmazonWishlists +
                            (this.props.charity.chewyWishlistUrl ? 1 : 0)}{" "}
                          wishlists
                        </span>
                      )}
                      {this.props.charity.storeUrl && (
                        <span className="want-count-text">
                          <StorefrontIcon
                            className="donated-thing-icon"
                            width="28px"
                          ></StorefrontIcon>
                          store
                        </span>
                      )}
                    </Accordion.Toggle>
                  </Link>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {numNeedItems > 0 && (
                        <div>
                          <h3 className="text-center">
                            <ItemNeedsIcon
                              className="needing-icon ui-icon"
                              width="38px"
                            ></ItemNeedsIcon>
                            needed items
                          </h3>
                        </div>
                      )}

                      <Row>{NeedList}</Row>

                      {/* INSTRUCTIONS ON HOW TO DONATE ITEMS */}
                      {this.props.donationInstructions && (
                        <p className="donation-instructions">
                          {this.props.donationInstructions}
                        </p>
                      )}

                      <Row>
                        {/* DROP-OFF LOCATION */}
                        {this.props.donationAddress && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Drop-off location:
                            </span>
                            <p className="donation-address">
                              {this.props.donationAddress}
                            </p>
                            {this.props.donationAddressMapLink && (
                              <a
                                className="maps-link"
                                href={this.props.donationAddressMapLink}
                                target="_blank"
                              >
                                open in maps
                              </a>
                            )}
                            {ButtonWithTopPopover(
                              "copy address",
                              this.props.donationAddress
                            )}
                          </Col>
                        )}

                        {/* ALTERNATE DROP-OFF LOCATION */}
                        {this.props.donationAddress2 && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Drop-off location:
                            </span>
                            <p className="donation-address">
                              {this.props.donationAddress2}
                            </p>
                            {ButtonWithTopPopover(
                              "copy address",
                              this.props.donationAddress2
                            )}
                          </Col>
                        )}

                        {/* ALTERNATE DROP-OFF LOCATION */}
                        {this.props.donationAddress3 && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Drop-off location:
                            </span>
                            <p className="donation-address">
                              {this.props.donationAddress3}
                            </p>
                            {ButtonWithTopPopover(
                              "copy address",
                              this.props.donationAddress3
                            )}
                          </Col>
                        )}

                        {/* ALTERNATE DROP-OFF LOCATION */}
                        {this.props.donationAddress4 && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Drop-off location:
                            </span>
                            <p className="donation-address">
                              {this.props.donationAddress4}
                            </p>
                            {ButtonWithTopPopover(
                              "copy address",
                              this.props.donationAddress4
                            )}
                          </Col>
                        )}

                        {/* ALTERNATE DROP-OFF LOCATION */}
                        {this.props.donationAddress5 && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Drop-off location:
                            </span>
                            <p className="donation-address">
                              {this.props.donationAddress5}
                            </p>
                            {ButtonWithTopPopover(
                              "copy address",
                              this.props.donationAddress5
                            )}
                          </Col>
                        )}

                        {/* ALTERNATE DROP-OFF LOCATION */}
                        {this.props.donationAddress6 && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Drop-off location:
                            </span>
                            <p className="donation-address">
                              {this.props.donationAddress6}
                            </p>
                            {ButtonWithTopPopover(
                              "copy address",
                              this.props.donationAddress6
                            )}
                          </Col>
                        )}

                        {/* DROP-OFF CONTACT PERSON */}
                        {this.props.donationCoordinationEmail && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              Contact:
                            </span>
                            <p className="donation-coordination-name">
                              {this.props.donationCoordinationName}
                            </p>
                            <p className="donation-email">
                              {donationCoordinationEmail}
                            </p>
                            {/* popover that says copied! and copies the email to clipboard */}
                            {ButtonWithTopPopover(
                              "copy email",
                              donationCoordinationEmail
                            )}
                          </Col>
                        )}

                        {/* DONATION FORM */}
                        {this.props.donationFormUrl && (
                          <Col sm={10} md={{ span: 5, offset: 1 }}>
                            <span className="donation-detail-label">
                              They ask that you fill out this form before
                              donating:
                            </span>
                            <br></br>
                            <a
                              href={this.props.donationFormUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                variant="light"
                                size="sm"
                                className="small-button"
                              >
                                fill out form on their website ↗
                              </Button>
                            </a>
                          </Col>
                        )}
                      </Row>

                      {!this.props.weCannotPickup && numNeedItems > 0 && (
                        <Row>
                          <Col sm={{ span: 10, offset: 1 }}>
                            <div className="arrange-pickup-container">
                              <IconPorchPickUp
                                width="210"
                                className="arrange-pickup-illustration"
                              />

                              <h6 className="arrange-pickup-title">
                                Heartening accepts donation drop-offs at our new
                                donation sorting room in North Central Austin on
                                Sundays 12noon - 5pm.
                              </h6>
                              <p className="arrange-pickup-description">
                                We donate 90% or more of the items we receive to
                                local causes and sell 10% or fewer to cover our
                                costs.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      )}

                      {!this.props.weCannotPickup && numNeedItems > 0 && (
                        <div className="text-center external-website-link-container">
                          <a
                            href="https://calendly.com/heartening-donations/donation-drop-off-at-the-donation-sorting-room"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="details-link"
                          >
                            <button
                              type="button"
                              className="donation-details-button charity-type"
                            >
                              <h5 className="details-title">
                                Schedule a donation drop-off →
                              </h5>
                            </button>
                          </a>
                        </div>
                      )}

                      {numAmazonWishlists +
                        (this.props.charity.chewyWishlistUrl ? 1 : 0) >
                        0 && (
                        <h3 className="text-center wishlist-heading">
                          <WishlistNeedsIcon
                            className="giving-icon ui-icon"
                            width="42px"
                          ></WishlistNeedsIcon>
                          wishlists
                        </h3>
                      )}
                      <Row>
                        {numAmazonWishlists > 0 && (
                          <Col
                            className="wishlist-items-container"
                            sm={12}
                            md={12}
                            lg={12}
                            key={this.props.charity.id + firstAmazonWishlistId}
                          >
                            <Row>{FirstAmazonWishlistButton}</Row>
                            <Row>{FirstWishlistAmazonItems}</Row>
                          </Col>
                        )}

                        {/* these are cols */}
                        {AdditionalAmazonWishlistButtons}

                        <Col
                          className="text-center"
                          sm={12}
                          md={12}
                          lg={12}
                          key={
                            this.props.charity.chewyWishlistUrl +
                            this.props.charity.id +
                            this.props.charityName
                          }
                        >
                          {this.props.charity.chewyWishlistUrl && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={this.props.charity.chewyWishlistUrl}
                              className="wishlist-link"
                            >
                              <Button
                                variant="light"
                                className="wishlist-button"
                              >
                                <h4 className="wishlist-title">
                                  <ChewyWishlistIcon
                                    className="chewy-icon wishlist-icon"
                                    width="30px"
                                  ></ChewyWishlistIcon>
                                  Chewy Wishlist for {this.props.charity.name}
                                </h4>
                              </Button>
                            </a>
                          )}
                        </Col>
                      </Row>
                      {this.props.charity.storeUrl && (
                        <div className="text-center">
                          <h3 className="text-center wishlist-heading">
                            <StorefrontIcon
                              className="giving-icon ui-icon"
                              width="42px"
                            ></StorefrontIcon>
                            store
                          </h3>
                          <a
                            href={this.props.charity.storeUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="details-link"
                          >
                            <Button
                              variant="light"
                              className="donation-details-button to-charity-site"
                            >
                              <h5 className="details-title">
                                {this.props.charity.storeName} →
                              </h5>
                            </Button>
                          </a>
                        </div>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Router>
    );
  }
}
