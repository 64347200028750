import React, { Component } from "react";
import { CardList } from "../../components/card-list/card-list.component";
import { SearchBox } from "../../components/search-box/search-box.component";
import { db } from "../../firebase/firebase.utils";
import Fuse from "fuse.js";
import { withRouter } from "react-router";

import "./homepage.styles.scss";

class HomePage extends Component {
  // component is already built into react so this leverages that
  constructor() {
    super(); // calls the constructor on the component class to give us access to state methods
    // now the state property exists on our App class

    this.state = {
      needItems: [], // sets some property on our state object
      searchField: "",
    };
  }

  componentDidMount() {
    // life cycle method
    // GET NEED ITEMS and set to array on the needItems property of state

    // const randomstart = Math.random().toString();

    // var firstPartRef = db.collection("needitems").orderBy("__name__").startAfter(randomstart).get();
    // var secondPartRef = db.collection("needitems").orderBy("__name__").endBefore(randomstart).get();
    // var fullRef = firstPartRef.push(secondPartRef);
    // const data = fullRef.docs.map(doc => doc.data());

    // this.setState({ needItems: data });

    // NON-RANDOMIZED PULL
    db.collection("needitems")
      .orderBy("priority", "desc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        //console.log(data); // array of items objects
        this.setState({ needItems: data });
      });

    // ADD A FIELD TO ALL FIREBASE DATA
    // db.collection("needitems").get().then(function(querySnapshot) {
    //   querySnapshot.forEach(function(doc) {
    //       doc.ref.update({
    //           priority: 0
    //       });
    //   });
    // });

    // fetch returns a promise
    // fetch('data.json')
    // .then(response => response.json()) // converts response to json
    // .then(items => this.setState({needItems:items})); // sets our needItems to the array of items

    // TO PRINT
    // .then(response => console.log(response))
    // .then(items => console.log(items))  // an array
  }
  // this arrow syntax handles binding 'this' to the place that the arrow function was defined in the first place
  // so the context of this arrow function is our App component
  // this is called 'lexically - scoped' function
  // when javascript first defined our app component, it saw this as an arrow function and
  // when it came into existence bound it to that context

  //OLD HANDLE CHANGE EVENT
  handleChange = (e) => {
    this.setState({ searchField: e.target.value, title: e.target.value });
    this.props.history.push(`${e.target.value}`);
  };

  // handleChange = (e) => {
  //   this.setState({ searchField: e.target.value, title: e.target.value }),
  //     () => {
  //       this.props.history.push(`/${e.target.value}`);
  //     };
  // };

  render() {
    // using a concept called destructuring which allows us to pull props off an obj and set them to constants
    // pulls the needItems and searchField props off state and sets them to contstants called needItems and searchField
    // its the euquivalent of saying const needItems = this.state.needItems; and const searchField = this.state.searchField;

    //OLD
    //const { needItems, searchField } = this.state; // the state property is what we want to pull the props off of

    const { needItems } = this.state;
    let { searchField } = this.state;

    const searchParam = this.props.match.params.searchParam;
    //console.log(searchParam);

    if (searchParam) {
      searchField = searchParam;
    }

    // const { needItems } = this.state;
    // const { searchField } = this.props.match.params.searchParam
    //   ? this.props.match.params.searchParam
    //   : this.state;
    // FILTERED NEED ITEMS WITH TRADITIONAL FILTER
    // const filteredNeedItems = needItems.filter( needItem =>
    //     // so our search isn't case sensitive
    //     // then asks if the string value passed inside of our includes is actually in the string being called on
    //     needItem.itemName.toLowerCase().includes(searchField.toLowerCase())
    //   );

    // FUSE - FUZZY SEARCH
    const options = {
      isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 2,
      location: 0,
      threshold: 0.3,
      distance: 100,
      useExtendedSearch: false,
      ignoreLocation: true,
      ignoreFieldNorm: false,
      keys: ["itemName", "description", "keywords", "category"], // add keywords in here
    };
    const fuse = new Fuse(needItems, options);
    // Change the pattern
    const pattern = searchField.toLowerCase();

    const fuseResults = fuse.search(pattern);

    const filteredNeedItems = searchField
      ? fuseResults.map((needItem) => needItem.item)
      : needItems;

    return (
      <div className="HomePage">
        <div className="SearchToDonate needs-item-search-container">
          {/* e represents the synthetic event*/}
          <SearchBox
            className="needs-item-input"
            placeholder="search for an item to give away"
            handleChange={this.handleChange}
          />

          {/* pass in the needItems as a prop from our App component*/}
          <CardList needItems={filteredNeedItems}></CardList>
        </div>
        {/* <Directory  /> */}
      </div>
    );
  }
}

export default withRouter(HomePage);
