import { createSelector } from 'reselect';

// there are two types of selectors: 1) an input selector which doesn't use createSelector 
// and 2) an output selector that does use input selectors and createSelectors to build themselves


// an input selector -- gets the whole state and returns a slice of it
const selectCart = state => state.cart;


// cartItems is a property on our cart
export const selectCartItems = createSelector(
    
    // takes two arguments, the first is an array of input selectors
    [selectCart],
    // the second is a function that will return the value we want out of the selector
    (cart) => cart.cartItems  // this function passes out the cart items

    // because we used creatSelector to create this, it is now a memoized selector
);

export const selectCartHidden = createSelector(
    [selectCart],
    cart => cart.hidden
);

// we use the most specific, smallest selector we can
export const selectCartItemsCount = createSelector(
  [selectCartItems],
  (cartItems) => 
    // this gives us back the total quantity of all the cart items
    cartItems.reduce(
        (accumulatedQuantity, cartItem) => accumulatedQuantity + cartItem.quantity, 0
    ) 
);

export const selectCartTotal = createSelector(
    [selectCartItems],
    (cartItems) => 
    // this gives us back the total quantity of all the cart items multplied by the price
    cartItems.reduce(
        (accumulatedQuantity, cartItem) => accumulatedQuantity + cartItem.quantity * cartItem.price, 0
    ) 
);