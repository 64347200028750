import React from "react";
import "./thumbnail-wishlist-item.styles.scss";

export default class ThumbnailWishlistItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemLink: this.itemLink,
      itemImg: this.itemImg,
      itemTitle: this.itemTitle,
      itemPrice: this.itemPrice,
      wishlistUrl: this.wishlistUrl,
    };
  }

  render() {
    const itemTitleFull = this.props.itemTitle;
    const itemTitle =
      itemTitleFull.length > 45 ? itemTitleFull + "..." : itemTitleFull;
    const itemImg = this.props.itemImg;
    const wishlistUrl = this.props.wishlistUrl;

    return (
      <div className="thumbnail-card">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={wishlistUrl}
          className="whole-wishlist-link"
        >
          <img src={itemImg} width="90px" height="90px" alt={itemTitle}></img>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={wishlistUrl}
          className="whole-wishlist-link"
        >
          <div className="wishlist-title-container">
            <p className="wishlist-item-name">{itemTitle}</p>
          </div>
        </a>
      </div>
    );
  }
}
