import React from "react";
import { Container, Row } from "react-bootstrap";
import Card from "../card/card.component";
import "./card-list.styles.scss";

// components take in props
export const CardList = (props) => (
  // className of card-list applies our css
  // .needItems was a prop that we passed in when we called CardList
  <Container className="card-list">
    <Row className="list-container">
      {props.needItems.map((
        needItem // map iterates over every element so the function we pass in will be called on each
      ) => (
        <Card
          key={needItem.itemName + needItem.charityId}
          needItem={needItem}
        /> // this passes a needItem into the Card component
      ))}
    </Row>
  </Container>
);
