import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

// Load Poppins typeface
require('typeface-poppins')

ReactDOM.render(
  // Provider is a component class that we get from react redux
  // Provider is the parent to everything in our app, gives access to the store
  // the persistor we wrap our app in is the persisted version of our store
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App/>
        </PersistGate>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
