import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist'; // allows browser to cache our store
import logger from 'redux-logger';
// we add middleware to our store so that when actions fire, we can catch them and display them

import rootReducer from './root-reducer';

// the middlewares that the store is expecting from redux is an array
// we set our logger middleware inside of this array
// we can add more middlewares in here if needed
const middlewares = [logger];

// to make our store
// gets a rootreducer and the return value of applyMiddleware
// the ... spreads in the indiviual values in the array as individual arguments to the function call
export const store = createStore(rootReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store); // this is essentially a persisted version of our store

export default { store, persistor };