// takes existing cart items and the one we want to add
export const addItemToCart = (cartItems, cartItemToAdd) => {
    const existingCartItem = cartItems.find(
        cartItem => cartItem.id === cartItemToAdd.id
    );
    // if the items we are trying to add is an existing cart item, update its quantity
    if(existingCartItem){
        return cartItems.map(cartItem => // this will return a new array
            cartItem.id === cartItemToAdd.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem 
            ); // returns a new version of our state so components re-render properly
    } 
    // if the item is not found in the existing cart items, return a new array with it appended and qty `
    return [...cartItems, {...cartItemToAdd, quantity: 1}];
};

// takes existing cart items and the one we want to add
export const removeItemFromCart = (cartItems, cartItemToRemove) => {
    const existingCartItem = cartItems.find(
        cartItem => cartItem.id === cartItemToRemove.id
    );
    // if the qty is equal to 1, and the person lowers the qty, it should remove
    // so check if qty equal to 1
    // filter keeps everything that isn't the id we want to remove
    if(existingCartItem.quantity === 1){
        return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id)
    } 
    // if the qty is not 1, then decrease the quantity. leave all other items the same
    return cartItems.map(
        cartItem =>
        cartItem.id === cartItemToRemove.id ?
        {...cartItem, quantity: cartItem.quantity - 1} // then return a new object, spread in the other props except change qty
        : cartItem // otherwise, just return the cartItem
    );
};