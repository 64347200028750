//import ReorderableList from "../../components/reorderable-list/reorderable-list.component";
import React, { useRef } from "react";
import clamp from "lodash-es/clamp";
import swap from "lodash-move";
import { useDrag } from "react-use-gesture";
import { useSprings, animated } from "react-spring";
import { Row, Col } from "react-bootstrap";

import "./admin-wishlist.styles.scss";

// Returns fitting styles for dragged/idle items
const fn = (order, active, originalIndex, curIndex, y) => (index) =>
  active && index === originalIndex
    ? {
        y: curIndex * 100 + y,
        scale: 1.1,
        zIndex: "1",
        shadow: 15,
        immediate: (n) => n === "y" || n === "zIndex",
      }
    : {
        y: order.indexOf(index) * 100,
        scale: 1,
        zIndex: "0",
        shadow: 1,
        immediate: false,
      };

function DraggableList({ items }) {
  const order = useRef(items.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(items.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(
      Math.round((curIndex * 100 + y) / 100),
      0,
      items.length - 1
    );
    const newOrder = swap(order.current, curIndex, curRow);
    setSprings(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) order.current = newOrder;
  });

  return (
    <Row className="page-container">
      <Col
        className="ranking-col"
        sm={1}
        md={{ span: 1, offset: 2 }}
        lg={{ span: 1, offset: 3 }}
      >
        <ol
          className="ranking-container"
          style={{ height: items.length * 100 }}
        >
          {items.map((items, i) => {
            return <li className="rank-label" key={i}></li>;
          })}
        </ol>
      </Col>

      <Col className="draggable-overall-container" sm={9} md={6} lg={5}>
        <div className="content" style={{ height: items.length * 100 }}>
          {springs.map(({ zIndex, shadow, y, scale }, i) => (
            <animated.div
              {...bind(i)}
              key={i}
              style={{
                zIndex,
                boxShadow: shadow.to(
                  (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                ),
                y,
                scale,
              }}
              children={items[i]}
              className="draggable-item-area"
            />
          ))}
        </div>
      </Col>
    </Row>
  );
}

function AdminWishlistPage() {
  return (
    <div className="admin-wishlist-page">
      <DraggableList items={"Lorem ipsum dolor it amut reget sit".split(" ")} />
    </div>
  );
}

export default AdminWishlistPage;
