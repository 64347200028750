import SHOP_DATA from "./shop.data";

const INITIAL_STATE = {
    collections: SHOP_DATA
};

const shopReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        // if no modifications are being returned, we do the default of just returning state
        default:
            return state;
    }
};

export default shopReducer;
// next, this gets pulled into our rootreducer