import { CartActionTypes } from './cart.types';
import { addItemToCart, removeItemFromCart } from '../../redux/cart/cart.utils';

const INITIAL_STATE = {
    hidden: true,
    cartItems: []
};

const cartReducer = (state = INITIAL_STATE, action) => {
    // action type is a string
    switch(action.type){
        case CartActionTypes.TOGGLE_CART_HIDDEN :
            return {
                // spread in everything else on the state
                ...state,
                hidden: !state.hidden  // this will convert to the opposite
            };
        case CartActionTypes.ADD_ITEM :
            return {
                ...state,
                // spreads in existing cart items already on our state first, then add the payload (the item we want to add)
                cartItems: addItemToCart(state.cartItems, action.payload)
            };

        case CartActionTypes.REMOVE_ITEM :
            return {
                ...state,
                // spreads in existing cart items already on our state first, then add the payload (the item we want to remove)
                cartItems: removeItemFromCart(state.cartItems, action.payload)
            };
        
        // this is step #3 in adding a new action...next bind it to our checkout item in the checkout item component
        case CartActionTypes.CLEAR_ITEM_FROM_CART :
            return {
                ...state,
                // filter returns us back anything that yields true inside the statement
                // if the id does not match the payload item id, then keep it
                cartItems: state.cartItems.filter(
                    cartItem => cartItem.id !== action.payload.id
                )
            };

        default:
            return state;
    }
};

export default cartReducer;